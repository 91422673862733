import { tailwindStyles } from '../constants'

const {
  button,
  buttonBackgroundStyle,
  externalBorderStyle,
  dialogue
} = tailwindStyles

const Dialog = ({ error, setError, message }) => {
  return (
    <dialog
      data-testid='error-dialog'
      open={error !== null}
      className={dialogue}
    >
      <form
        method='dialog'
        className='flex flex-col w-80 mx-auto mt-5'
      >
        <span data-testid='error-message' className='text-white'>
          {message}
        </span>
        <button
          data-testid='error-button'
          className={
            button +
            buttonBackgroundStyle +
            externalBorderStyle +
            ' w-10 mx-auto mt-5'
          }
          onClick={() => setError !== undefined && setError(null)}

        >
          OK
        </button>
      </form>
    </dialog>
  )
}

export default Dialog
