/* Copyright 2023 Swanbarton Limited */
import { tailwindStyles } from '../constants'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const MAX_UINT32 = 2 ** 32

/**
 * @param {Object} props
 * uncontrolled HTML input elements
 *
 * @returns {React.ReactElement} - Component that renders HTML input elements
 * and their associated labels for entering the site's general parameters
 */
const SiteParameters = ({
  scenarioData,
  setScenarioData,
  scenarioIndex
}) => {
  const handleChange = (event, property) => {
    const updateScenario = [...scenarioData]
    const { target } = event

    updateScenario[scenarioIndex].tariffData[property] = target.value !== ''
      ? Number(target.value)
      : ''

    event.target.form.requestSubmit()
    setScenarioData(updateScenario)
  }

  return (
    <section
      className={
        'grid grid-cols-2 px-2 py-2 w-[530px] content-center m-1' +
        tailwindStyles.externalBorderStyle +
        tailwindStyles.containerBackgroundStyle
      }
    >
      <label
        className={tailwindStyles.label + ' col-span-1 col-start-1'}
        htmlFor={'electricity-usage-' + scenarioIndex}
      >
        Total annual electricity usage (kWh)
      </label>
      <Tippy placement='right' content='Total annual on-site consumption of electricity imported from the grid.'>
        <input
          className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle + ' col-span-1 col-start-2'}
          type='number'
          min={0}
          max={MAX_UINT32}
          id={'electricity-usage' + scenarioIndex}
          value={String(scenarioData[scenarioIndex].tariffData.usage)}
          data-testid={'electricity-usage' + scenarioIndex}
          onChange={e => handleChange(e, 'usage')}
          inputMode='decimal'
        />
      </Tippy>
      <label
        className={tailwindStyles.label + ' col-span-1 col-start-1'}
        htmlFor={'electricity-cost' + scenarioIndex}
      >
        Total annual electricity cost (£)
      </label>
      <Tippy
        placement='right'
        content='Total annual GBP cost associated with importing electricity from the grid for use on-site.'
      >
        <input
          className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle + ' col-span-1 col-start-2'}
          type='number'
          min={0}
          max={MAX_UINT32}
          step='0.01'
          id={'electricity-cost' + scenarioIndex}
          value={scenarioData[scenarioIndex].tariffData.cost}
          data-testid={'electricity-cost' + scenarioIndex}
          onChange={e => handleChange(e, 'cost')}
          inputMode='decimal'
        />
      </Tippy>
    </section>
  )
}

export default SiteParameters
