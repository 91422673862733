/* Copyright 2023 Swanbarton Limited */
import ConsumptionAssets from './ConsumptionAssets'
import SiteParameters from './SiteParameters'
import GenerationAssets from './GenerationAssets'
import Tariff from './Tariff'
import { tailwindStyles } from '../constants'
import FuelConfiguration from './FuelConfiguration'

/**
 *
 * @constructor
 */
const Inputs = ({
  scenarioData,
  setScenarioData,
  scenarioIndex,
  energyVectors,
  electrolysisMethods,
  contentTab
}) => {
  return (
    <form
      data-testid={'inputs-' + scenarioIndex} onSubmit={
      (e) => {
        e.preventDefault()
      }
    }
    >
      {(contentTab === 'Fuel Costs') &&
        <>
          <h2
            className={tailwindStyles.h2}
          >
            Electricity Tariff Parameters
          </h2>
          <section className='block ml-5'>
            <SiteParameters
              scenarioData={scenarioData}
              setScenarioData={setScenarioData}
              scenarioIndex={scenarioIndex}
            />
            <Tariff
              scenarioData={scenarioData}
              setScenarioData={setScenarioData}
              scenarioIndex={scenarioIndex}
            />
          </section>
          <h2
            className={tailwindStyles.h2 + ' mb-3'}
          >
            Fuel Vectors
          </h2>
          <section className='ml-5'>
            <FuelConfiguration
              energyVectors={energyVectors}
              scenarioData={scenarioData}
              setScenarioData={setScenarioData}
              scenarioIndex={scenarioIndex}
            />
          </section>
        </>}
      {(contentTab === 'Consumption Assets') &&
        <section>
          <ConsumptionAssets
            vectors={energyVectors}
            scenarioData={scenarioData}
            setScenarioData={setScenarioData}
            scenarioIndex={scenarioIndex}
            configurableVectors={scenarioData[scenarioIndex].vectorData}
          />
        </section>}
      {(contentTab === 'Generation Assets') &&
        <section>
          <GenerationAssets
            scenarioData={scenarioData}
            setScenarioData={setScenarioData}
            scenarioIndex={scenarioIndex}
            electrolysisMethods={electrolysisMethods}
          />
        </section>}
    </form>
  )
}

export default Inputs
