/* Copyright 2023 Swanbarton Limited */

import { Children, useContext, useEffect } from 'react'
import { toCurrency } from '../utils/toCurrency'
import { GRID_INTENSITY, tailwindStyles } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'
import { getKwhInfoFromInputs } from '../utils/getKwhInfoFromInputs'
import { calculateElectricityRate } from '../utils/calculateElectricityRate'
import RenewableMetricsGraph from './RenewableMetricsGraph'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { totalHydrogenConsumption } from '../utils/totalHydrogenConsumption'
import { MetricsContext } from './App'
import { convertKgToTonnes } from '../utils/convertKgToTonnes'

const TOOLTIPS = {
  generationAssetMetrics: {
    maximumGeneration: [
      'Maximum power output associated with electricity that can be generated using the configured generation asset.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Peak output power × 24 × 365 × Capacity factor of asset)'
    ],
    potentialDemandMet: [
      'Percentage of the total annual electricity demand that could be met by the configured generation asset.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum asset generation / Total electricity demand)'
    ],
    potentialEmissionsSaving: [
      'Potential CO₂ emission reduction that could be achieved annual by maximising the use of electricity produced' +
      ' using the configured generation asset.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum asset generation × Grid import carbon intensity)'
    ],
    potentialCostSaving: [
      'Potential annual GBP cost that could be saved by maximising the use of electricity produced using the ' +
      'configured renewable generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum renewable asset generation × Grid cost per kWh)'
    ],
    capex: [
      'Capital expenditure associated with the installation of the configured generation asset.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Peak power × Capital cost per kW)'
    ],
    roi: [
      'Estimated number of years required for return on investment with respect to generation asset installation.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Capital expenditure / Potential annual cost reduction)'
    ]
  },
  overview: {
    totalConsumption: [
      'Total annual consumption across all consumption assets configured to make use of electricity.'
    ],
    maximumGeneration: [
      'Maximum power output associated with electricity that can be produced using the configured generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Sum of maximum generation of all generation assets)'
    ],
    demandMet: [
      'Percentage of the total annual electricity demand met by the configured generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Total maximum generation / Total electricity demand)'
    ],
    emissionsSaving: [
      'Annual CO₂ emission reduction associated with attempting to meet electricity demand with generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Total maximum generation × Grid import carbon intensity)'
    ],
    costSaving: [
      'Annual GBP cost saving associated with attempting to meet electricity demand with generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Total maximum generation × Grid cost per kWh)'
    ]
  },
  assetMetrics: {
    importEmissions: [
      'Annual CO₂ emissions associated with the sole use of imported electricity to meet asset power requirements.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Asset electricity consumption × Grid import carbon intensity)'
    ],
    totalEmissions: [
      'Annual CO₂ emissions associated with attempting to meet electricity demand using configured generation assets.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Proportion of electricity imported from grid to meet asset demand × Grid import carbon intensity)'
    ],
    emissionsSaving: [
      'Annual CO₂ emissions saved when attempting to meet electricity demand using configured generation assets. If' +
      ' electricity demand can not be met, this also includes CO₂ emissions of importing the remaining electricity ' +
      'from the grid.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Import only emissions - Emissions with renewable generation)'
    ],
    importCost: [
      'Annual GBP cost associated with operation of the individual asset using solely imported electricity to meet' +
      ' asset power requirements.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Annual electricity consumption × Current fuel cost)'
    ],
    totalCost: [
      'Annual GBP cost associated with operation of the individual asset while attempting to meet electricity demand' +
      ' using configured generation assets. If electricity demand can not be met, this also includes CO₂ emissions' +
      ' of importing the remaining electricity from the grid.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Proportion of electricity imported from grid × Annual electricity consumption × Grid cost per kWh)'
    ],
    costSaving: [
      'Total annual GBP cost saving when maximising use of configured generation assets to meet individual asset' +
      ' demand.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Import only cost - Cost with renewable generation)'
    ],
    capex: [
      'Capital expenditure associated with fuel vector conversion.'
    ],
    assetRoi: [
      'Estimated number of years required for return on investment with respect to asset fuel vector conversion.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Capital expenditure / Annual cost reduction)'
    ]
  }
}

/**
 *
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @param {Array.<Object>} consumptionAssets - An array of consumption asset objects
 * @param {Object} siteTariff - An object describing the tariff associated with site operation
 * @param {Array.<Object>} generationAssets - Array of potential generation methods, configured using
 * Inputs.js which draws properties from config
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * associated with the production of on-site hydrogen
 * @param {Object} electrolyser - An array of consumption asset objects
 * @param {array.<Object>} electrolysisMethods - list of all potential electrolysis methods (taken from config)
 * @param {array.<Object>} generationMethods - list of all potential generation methods (taken from config)
 * @returns {React.ReactElement} - An HTML table describing the consumption metrics
 * @constructor
 */
const RenewableMetrics = ({
  energyVectors,
  consumptionAssets,
  siteTariff,
  generationAssets,
  configurableVectors,
  electrolyser,
  electrolysisMethods,
  generationMethods,
  scenarioIndex,
  contentTab
}) => {
  const electricityRate = calculateElectricityRate(siteTariff)

  let shouldDisplayAssetMetrics = false

  if (electrolyser.enabledStatus === true && electrolyser.peakPower !== '') {
    shouldDisplayAssetMetrics = true
  }
  if (siteTariff.usage) {
    shouldDisplayAssetMetrics = true
  }
  if (
    consumptionAssets.filter(asset => (
      (
        (
          asset.toConvert === true && asset.newVector === 'Electricity'
        ) || (
          asset.toConvert === false && asset.currentVector === 'Electricity'
        )
      ))).length > 0 && generationAssets.filter(genAsset => genAsset.enabledStatus === true).length > 0
  ) {
    shouldDisplayAssetMetrics = true
  }

  const onSiteElectricalAssets = consumptionAssets.filter(asset =>
    (
      asset.toConvert === true && asset.newVector === 'Electricity'
    ) || (
      asset.toConvert === false && asset.currentVector === 'Electricity'
    )
  )

  const generationVectorIndices = []
  consumptionAssets.forEach(
    (asset, index) => {
      if (
        (
          asset.toConvert === true && asset.newVector === 'Electricity'
        ) || (
          asset.toConvert === false && asset.currentVector === 'Electricity'
        )) {
        generationVectorIndices.push(index)
      }
    }
  )

  /**
   * Total site energy consumption by hydrogen sources
   * @type {number}
   */
  let totalCurrentKwhDemand = 0
  onSiteElectricalAssets.forEach(asset => {
    const currentEfficiencyToUse = asset.currentEfficiency || 100
    const newEfficiencyToUse = asset.newEfficiency || 100

    const oldVectorUserInputs = (asset.currentVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === asset.currentVector)

    const currentEnergyVector = (asset.currentVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === asset.currentVector)

    const totalAnnualConsumption = asset.annualConsumption * asset.quantity

    /**
     * total annual consumption currently associated with a single asset in kWh
     * @type {number}
     */
    let currentKwh
    if (oldVectorUserInputs === 'Electricity') {
      currentKwh = totalAnnualConsumption
    } else {
      if (oldVectorUserInputs.unit.toLowerCase() === 'litres') {
        currentKwh = totalAnnualConsumption * currentEnergyVector.energyDensityLitres / 1000
      } else if (oldVectorUserInputs.unit.toLowerCase() === 'kg') {
        currentKwh = totalAnnualConsumption * currentEnergyVector.energyDensityKg / 1000
      } else {
        currentKwh = totalAnnualConsumption
      }
    }
    if (asset.toConvert) {
      totalCurrentKwhDemand += currentKwh *
        (currentEfficiencyToUse / newEfficiencyToUse)
    } else {
      totalCurrentKwhDemand += currentKwh
    }
  })

  if (siteTariff.usage) {
    totalCurrentKwhDemand += siteTariff.usage
  }

  if (electrolyser.enabledStatus === true && electrolyser.peakPower !== '') {
    const maxPotentialProduction = 365 * 24 * electrolyser.peakPower * electrolyser.capacityFactor / 100
    const electrolysisMethod = electrolysisMethods.find(v => v.methodName === electrolyser.electrolyserType)
    totalCurrentKwhDemand += electrolysisMethod.electricityRequirement * maxPotentialProduction
  }

  const activeGenerationAssets = generationAssets.filter(genAsset => genAsset.enabledStatus === true)

  /**
   * total potential production of all enabled generations assets in kWh
   * @type {number}
   */
  let maxPotentialProduction = 0
  activeGenerationAssets.forEach(genAsset => {
    if (genAsset.peakPower !== '' && genAsset.capacityFactor !== '') {
      const potentialProduction = 365 * 24 * genAsset.peakPower * genAsset.capacityFactor / 100
      maxPotentialProduction += potentialProduction
    }
  })

  /**
   * Percentage of total consumption that could be supplied by on-site generation assets
   * @type {number}
   */
  let demandRatio
  if (maxPotentialProduction !== 0 && totalCurrentKwhDemand !== 0) {
    demandRatio = maxPotentialProduction > totalCurrentKwhDemand
      ? 1
      : maxPotentialProduction / totalCurrentKwhDemand
  } else {
    demandRatio = 0
  }

  const demandMet = (Math.round(10 * 100 * demandRatio) / 10).toString() + '%'

  const kwhReduction = demandRatio * totalCurrentKwhDemand
  const costSaving = Math.round(kwhReduction * electricityRate)
  const emissionsSaving = Math.round(kwhReduction * GRID_INTENSITY)

  const printTotalCurrentKwhDemand = Math.round(totalCurrentKwhDemand)

  const renewablesTableBody = activeGenerationAssets.map(({
    methodName,
    peakPower,
    capacityFactor
  }) => {
    const method = generationMethods.find(v => v.methodName === methodName)

    const individualKwhValue = (peakPower === 0 || peakPower === '' || capacityFactor === '')
      ? 0
      : 365 * 24 * peakPower * capacityFactor / 100

    const percentageValue = (totalCurrentKwhDemand)
      ? Math.round(10 * 100 * individualKwhValue / totalCurrentKwhDemand) / 10
      : 0
    const percentageString = percentageValue + '%'

    const individualEmissionsSaving = Math.round(
      individualKwhValue * GRID_INTENSITY
    )

    const individualCostSaving = Math.round(
      individualKwhValue * electricityRate
    )

    const capEx = (peakPower)
      ? Math.round(peakPower * method.capitalCost)
      : 0

    const roi = (capEx && individualCostSaving > 0)
      ? Math.round(100 * capEx / individualCostSaving) / 100
      : 0

    return [
      methodName,
      individualKwhValue,
      percentageString,
      individualEmissionsSaving,
      individualCostSaving,
      capEx,
      roi
    ]
  })

  const assetTableBody = onSiteElectricalAssets.map(({
    annualConsumption,
    currentVector,
    currentEfficiency,
    newVector,
    newEfficiency,
    quantity,
    assetName,
    toConvert,
    capex
  }, index) => {
    const currentEfficiencyToUse = currentEfficiency || 100

    const newEfficiencyToUse = newEfficiency || 100

    const currentEnergyVector = (currentVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === currentVector)

    const currentVectorUserInputs = (currentVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === currentVector)

    const totalAnnualConsumption = annualConsumption * quantity

    const currentKwhInfo = getKwhInfoFromInputs(
      currentEnergyVector,
      currentVectorUserInputs,
      totalAnnualConsumption
    )

    const currentKwhDemand = currentKwhInfo.kWh
    /**
     * total annual consumption currently associated with a single asset in kWh
     * @type {number}
     */
    let newKwhDemand
    if (toConvert) {
      newKwhDemand = currentKwhDemand *
        (currentEfficiencyToUse / newEfficiencyToUse)
    } else {
      newKwhDemand = currentKwhDemand
    }

    const costAllImport = Math.round(newKwhDemand * electricityRate)
    const emissionsAllImport = Math.round(newKwhDemand * GRID_INTENSITY)

    const newCost = Math.round((1 - demandRatio) * costAllImport)
    const newEmissions = Math.round((1 - demandRatio) * emissionsAllImport)

    const emissionsDifference = emissionsAllImport - newEmissions
    const costDifference = costAllImport - newCost

    const capexOut = (toConvert && capex)
      ? parseFloat(capex) * quantity
      : 0

    const assetRoi = (toConvert && capex && costDifference && costDifference > 0)
      ? Math.round(100 * capexOut / costDifference) / 100
      : 0

    return [
      assetName === '' ? `Asset ${generationVectorIndices[index] + 1}` : assetName,
      emissionsAllImport,
      newEmissions,
      emissionsDifference,
      costAllImport,
      newCost,
      costDifference,
      capexOut,
      assetRoi
    ]
  })

  if (siteTariff.usage) {
    const emissionsAllImport = Math.round(siteTariff.usage * GRID_INTENSITY)
    const newEmissions = Math.round((1 - demandRatio) * emissionsAllImport)
    const emissionsDifference = emissionsAllImport - newEmissions
    const costAllImport = Math.round(siteTariff.usage * electricityRate)
    const newCost = Math.round((1 - demandRatio) * costAllImport)
    const costDifference = costAllImport - newCost
    const capexOut = 0
    const assetRoi = 0

    assetTableBody.push([
      'Site usage',
      emissionsAllImport,
      newEmissions,
      emissionsDifference,
      costAllImport,
      newCost,
      costDifference,
      capexOut,
      assetRoi
    ])
  }

  if (electrolyser.enabledStatus === true && electrolyser.peakPower !== '') {
    const totalHydrogenFuelDemand = totalHydrogenConsumption(consumptionAssets, energyVectors, configurableVectors)
    const maximumHydrogenFuelProduction = 365 * 24 * electrolyser.peakPower * electrolyser.capacityFactor / 100

    const hydrogenProductionToMeetDemand = (maximumHydrogenFuelProduction > totalHydrogenFuelDemand)
      ? totalHydrogenFuelDemand
      : maximumHydrogenFuelProduction

    const excessHydrogenFuelProduction = (maximumHydrogenFuelProduction > totalHydrogenFuelDemand)
      ? maximumHydrogenFuelProduction - totalHydrogenFuelDemand
      : 0

    const electrolysisMethod = electrolysisMethods.find(v => v.methodName === electrolyser.electrolyserType)

    const capexOut = electrolyser.peakPower * electrolysisMethod.capitalCost

    const additionalOperationCost = Math.round(
      electrolysisMethod.additionalOperationCost * electrolyser.peakPower
    )

    if (hydrogenProductionToMeetDemand) {
      let emissionsAllImportDemand = Math.round(
        electrolysisMethod.electricityRequirement * hydrogenProductionToMeetDemand * GRID_INTENSITY
      )
      let newEmissionsDemand = Math.round(
        (1 - demandRatio) * emissionsAllImportDemand
      )
      let emissionsDifferenceDemand = emissionsAllImportDemand - newEmissionsDemand

      const operationalCost = additionalOperationCost * hydrogenProductionToMeetDemand / maximumHydrogenFuelProduction
      const costAllImportDemandNoOperationCost = Math.round(
        electrolysisMethod.electricityRequirement * hydrogenProductionToMeetDemand * electricityRate
      )
      const costAllImportDemand = costAllImportDemandNoOperationCost + operationalCost
      const newCostDemandNoOperationCost = Math.round((1 - demandRatio) * costAllImportDemand)
      const newCostDemand = newCostDemandNoOperationCost + operationalCost

      const costDifferenceDemand = costAllImportDemand - newCostDemand

      const assetRoi = (capexOut && costDifferenceDemand > 0)
        ? Math.round(100 * capexOut / costDifferenceDemand) / 100
        : 0

      emissionsAllImportDemand = convertKgToTonnes(emissionsAllImportDemand, 2)
      newEmissionsDemand = convertKgToTonnes(newEmissionsDemand, 2)
      emissionsDifferenceDemand = convertKgToTonnes(emissionsDifferenceDemand, 2)

      assetTableBody.push([
        'Hydrogen electrolyser (on-site fuel demand)',
        emissionsAllImportDemand,
        newEmissionsDemand,
        emissionsDifferenceDemand,
        costAllImportDemand,
        newCostDemand,
        costDifferenceDemand,
        capexOut,
        assetRoi
      ])
    }

    if (excessHydrogenFuelProduction) {
      let emissionsAllImportExcess = Math.round(
        electrolysisMethod.electricityRequirement * excessHydrogenFuelProduction * GRID_INTENSITY
      )
      let newEmissionsExcess = Math.round(
        (1 - demandRatio) * emissionsAllImportExcess
      )
      let emissionsDifferenceExcess = emissionsAllImportExcess - newEmissionsExcess

      const operationalCost = additionalOperationCost * excessHydrogenFuelProduction / maximumHydrogenFuelProduction
      const costAllImportExcessNoOperationCost = Math.round(
        electrolysisMethod.electricityRequirement * excessHydrogenFuelProduction * electricityRate
      )
      const costAllImportExcess = costAllImportExcessNoOperationCost + operationalCost
      const newCostExcessNoOperationCost = Math.round((1 - demandRatio) * costAllImportExcess)
      const newCostExcess = newCostExcessNoOperationCost + operationalCost
      const costDifferenceExcess = costAllImportExcess - newCostExcess

      const assetRoi = (capexOut && costDifferenceExcess > 0)
        ? Math.round(100 * capexOut / costDifferenceExcess) / 100
        : 0

      emissionsAllImportExcess = convertKgToTonnes(emissionsAllImportExcess, 2)
      newEmissionsExcess = convertKgToTonnes(newEmissionsExcess, 2)
      emissionsDifferenceExcess = convertKgToTonnes(emissionsDifferenceExcess, 2)

      assetTableBody.push([
        'Hydrogen electrolyser (excess fuel generation)',
        emissionsAllImportExcess,
        newEmissionsExcess,
        emissionsDifferenceExcess,
        costAllImportExcess,
        newCostExcess,
        costDifferenceExcess,
        capexOut,
        assetRoi
      ])
    }
  }

  let maxRoi = 0
  assetTableBody.forEach((row, index) => {
    if (row[row.length - 1] > maxRoi) {
      maxRoi = row[row.length - 1]
    }
  })

  if (assetTableBody.length > 1) {
    assetTableBody.push([
      'Total',
      assetTableBody.reduce((acc, [
        _,
        emissionsAllImportExcess]) => acc + Math.round(emissionsAllImportExcess), 0),
      assetTableBody.reduce((acc, [
        _, __,
        newEmissionsExcess]) => acc + Math.round(newEmissionsExcess), 0),
      assetTableBody.reduce((acc, [
        _, __, ___,
        emissionsDifferenceExcess]) => acc + Math.round(emissionsDifferenceExcess), 0),
      assetTableBody.reduce((acc, [
        _, __, ___, ____,
        costAllImportExcess]) => acc + costAllImportExcess, 0),
      assetTableBody.reduce((acc, [
        _, __, ___, ____, _____,
        newCostExcess]) => acc + newCostExcess, 0),
      assetTableBody.reduce((acc, [
        _, __, ___, ____, _____, ______,
        costDifferenceExcess]) => acc + costDifferenceExcess, 0),
      assetTableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______,
        capexOut]) => acc + capexOut, 0),
      maxRoi
    ])
  }

  const { metrics } = useContext(MetricsContext)

  const shouldRender = generationAssets.some(asset => asset.enabledStatus)

  useEffect(() => {
    const newMetrics = { ...metrics.current }

    if (shouldRender) {
      newMetrics.scenarioMetrics[scenarioIndex].renewables = [...renewablesTableBody]
      newMetrics.scenarioMetrics[scenarioIndex].renewables.unshift([
        'Generation method',
        'Annual predicted generation (kWh)',
        'Electricity demand met (%)',
        'Potential annual emissions reduction (t CO₂e)',
        'Potential annual cost reduction (GBP)',
        'Capital expenditure (GBP)',
        'Estimated ROI (years)'
      ])
    } else {
      newMetrics.scenarioMetrics[scenarioIndex].renewables = []
    }

    if (shouldDisplayAssetMetrics) {
      newMetrics.scenarioMetrics[scenarioIndex].assetTable = [...assetTableBody]
      newMetrics.scenarioMetrics[scenarioIndex].assetTable.unshift([
        'Asset name',
        'Annual emissions importing from grid (t CO₂e)',
        'Annual emissions with renewable generation (t CO₂e)',
        'Annual emissions reduction (t CO₂e)',
        'Annual cost importing from grid (GBP)',
        'Annual cost with contribution from renewables (GBP)',
        'Annual total cost reduction (GBP)',
        'Capital expenditure (GBP)',
        'Estimated ROI (years)'
      ])

      newMetrics.scenarioMetrics[scenarioIndex].totalRenewables = [
        [
          'Total on-site electricity consumption (kWh)',
          'Maximum generation from renewables (kWh)',
          'Total on-site electricity demand met (%)',
          'Annual total emissions reduction (t CO₂e)',
          'Annual total cost reduction (GBP)'
        ],
        [
          totalCurrentKwhDemand || '',
          maxPotentialProduction || '',
          demandMet,
          emissionsSaving || '',
          costSaving
        ]
      ]
    } else {
      newMetrics.scenarioMetrics[scenarioIndex].assetTable = []
      newMetrics.scenarioMetrics[scenarioIndex].totalRenewables = []
    }

    metrics.current = newMetrics
  }, [
    generationAssets, shouldRender, shouldDisplayAssetMetrics, assetTableBody,
    costSaving, demandMet, emissionsSaving, maxPotentialProduction, metrics,
    renewablesTableBody, totalCurrentKwhDemand
  ])

  if (shouldRender) {
    return (
      <>
        {contentTab === 'Metrics' &&
          <table
            className={tailwindStyles.table}
            data-testid='renewable-method-table'
          >
            <caption
              data-testid='renewable-method-caption'
              className={tailwindStyles.h3}
            >
              Generation asset metrics
            </caption>
            <thead data-testid='renewable-method-table-head'>
              <tr className={tailwindStyles.tableHeader} data-testid='renewable-method-header-row'>
                <th data-testid='renewable-method-header-col-0'>Generation Method</th>
                <Tippy content={TOOLTIPS.generationAssetMetrics.maximumGeneration}>
                  <th data-testid='renewable-method-header-col-1'>Annual predicted generation (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.generationAssetMetrics.potentialDemandMet}>
                  <th data-testid='renewable-method-header-col-2'>Percentage of total electricity demand met</th>
                </Tippy>
                <Tippy content={TOOLTIPS.generationAssetMetrics.potentialEmissionsSaving}>
                  <th data-testid='renewable-method-header-col-3'>Potential annual emissions reduction (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.generationAssetMetrics.potentialCostSaving}>
                  <th data-testid='renewable-method-header-col-4'>Potential annual cost reduction</th>
                </Tippy>
                <Tippy content={TOOLTIPS.generationAssetMetrics.capex}>
                  <th data-testid='renewable-method-header-col-5'>Capital expenditure</th>
                </Tippy>
                <Tippy content={TOOLTIPS.generationAssetMetrics.roi}>
                  <th data-testid='renewable-method-header-col-6'>Estimated ROI (years)</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='renewable-method-table-body'>
              {Children.toArray(renewablesTableBody.map(([
                methodName,
                individualKwhValue,
                percentageValue,
                individualEmissionsSaving,
                individualCostSaving,
                capex,
                roi
              ], index) => {
                return (
                  <tr className={tailwindStyles.tableRow} data-testid={`renewable-method-table-row-${index}`}>
                    <td data-testid={`renewable-method-data-${index}0`}>{capitaliseFirstLetter(methodName)}</td>
                    <td data-testid={`renewable-method-data-${index}1`}>{individualKwhValue || '-'}</td>
                    <td data-testid={`renewable-method-data-${index}2`}>{percentageValue}</td>
                    <td data-testid={`renewable-method-data-${index}3`}>{individualEmissionsSaving || '-'}</td>
                    <td data-testid={`renewable-method-data-${index}4`}>{toCurrency(individualCostSaving)}</td>
                    <td data-testid={`renewable-method-data-${index}5`}>{toCurrency(capex)}</td>
                    <td data-testid={`renewable-method-data-${index}6`}>{roi || '-'}</td>
                  </tr>
                )
              }
              ))}
            </tbody>
          </table>}
        {(shouldDisplayAssetMetrics && contentTab === 'Metrics') &&
          <table
            className={tailwindStyles.table}
            data-testid='generation-metrics-table'
          >
            <caption
              className={tailwindStyles.h3}
              data-testid='generation-metrics-caption'
            >
              Electricity consumption metrics with generation
            </caption>
            <thead data-testid='generation-table-head'>
              <tr
                className={tailwindStyles.tableHeader}
                data-testid='generation-header-row'
              >
                <Tippy content={TOOLTIPS.overview.totalConsumption}>
                  <th data-testid='generation-header-col-0'>Total on-site electricity consumption (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.maximumGeneration}>
                  <th data-testid='generation-header-col-1'>Maximum generation from renewables (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.demandMet}>
                  <th data-testid='generation-header-col-2'>Total on-site electricity demand met (%)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.emissionsSaving}>
                  <th data-testid='generation-header-col-3'>Annual total emissions reduction (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.costSaving}>
                  <th data-testid='generation-header-col-4'>Annual total cost reduction</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='generation-table-body'>
              <tr
                className={tailwindStyles.tableRow}
                data-testid='generation-table-row-0'
              >
                <td data-testid='generation-table-data-0'>{printTotalCurrentKwhDemand || '-'}</td>
                <td data-testid='generation-table-data-1'>{maxPotentialProduction || '-'}</td>
                <td data-testid='generation-table-data-2'>{demandMet}</td>
                <td data-testid='generation-table-data-3'>{emissionsSaving || '-'}</td>
                <td data-testid='generation-table-data-4'>{toCurrency(costSaving)}</td>
              </tr>
            </tbody>
          </table>}
        {(shouldDisplayAssetMetrics && contentTab === 'Metrics') &&
          <table
            className={tailwindStyles.table}
            data-testid='generation-assets-table'
          >
            <caption
              className={tailwindStyles.h3}
              data-testid='generation-assets-caption'
            >
              Individual electricity asset metrics with generation
            </caption>
            <thead data-testid='generation-assets-head'>
              <tr
                className={tailwindStyles.tableHeader}
                data-testid='generation-assets-header-row'
              >
                <th data-testid='generation-asset-header-col-0'>Asset name</th>
                <Tippy content={TOOLTIPS.assetMetrics.importEmissions}>
                  <th data-testid='generation-asset-header-col-1'>Annual emissions importing from grid (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.emissionsSaving}>
                  <th data-testid='generation-asset-header-col-2'>Annual emissions with renewable generation (t CO₂e)
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.totalEmissions}>
                  <th data-testid='generation-asset-header-col-3'>Annual emissions reduction (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.importCost}>
                  <th data-testid='generation-asset-header-col-4'>Annual cost importing from grid</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.costSaving}>
                  <th data-testid='generation-asset-header-col-5'>Annual cost with contribution from renewables</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.totalCost}>
                  <th data-testid='generation-asset-header-col-6'>Annual total cost reduction</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.capex}>
                  <th data-testid='generation-asset-header-col-7'>Capital expenditure</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.assetRoi}>
                  <th data-testid='generation-asset-header-col-8'>Estimated ROI (years)</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='generation-asset-table-body'>
              {Children.toArray(assetTableBody.map(([
                assetName,
                emissionsAllImport,
                newEmissions,
                emissionsDifference,
                costAllImport,
                newCost,
                costDifference,
                capexOut,
                assetRoi
              ], index) => {
                const printNewCost = (newCost === 0 && emissionsAllImport && emissionsAllImport && electricityRate)
                  ? '£0'
                  : toCurrency(newCost)

                const printNewEmissions = (emissionsAllImport)
                  ? newEmissions
                  : '-'

                if (assetName !== 'Total') {
                  return (
                    <tr className={tailwindStyles.tableRow} data-testid={`generation-asset-table-row-${index}`}>
                      <td data-testid={`generation-asset-data-${index}0`}>{capitaliseFirstLetter(assetName)}</td>
                      <td data-testid={`generation-asset-data-${index}1`}>{emissionsAllImport || '-'}</td>
                      <td data-testid={`generation-asset-data-${index}2`}>{printNewEmissions}</td>
                      <td data-testid={`generation-asset-data-${index}3`}>{emissionsDifference || '-'}</td>
                      <td data-testid={`generation-asset-data-${index}4`}>{toCurrency(costAllImport)}</td>
                      <td data-testid={`generation-asset-data-${index}5`}>{printNewCost}</td>
                      <td data-testid={`generation-asset-data-${index}6`}>{toCurrency(costDifference)}</td>
                      <td data-testid={`generation-asset-data-${index}7`}>{toCurrency(capexOut)}</td>
                      <td data-testid={`generation-asset-data-${index}8`}>{assetRoi || '-'}</td>
                    </tr>
                  )
                }
                return (
                  <tr className={tailwindStyles.tableHeader} data-testid={`generation-asset-table-row-${index}`}>
                    <th data-testid={`generation-asset-data-${index}0`}>{capitaliseFirstLetter(assetName)}</th>
                    <th data-testid={`generation-asset-data-${index}1`}>{emissionsAllImport || '-'}</th>
                    <th data-testid={`generation-asset-data-${index}2`}>{printNewEmissions}</th>
                    <th data-testid={`generation-asset-data-${index}3`}>{emissionsDifference || '-'}</th>
                    <th data-testid={`generation-asset-data-${index}4`}>{toCurrency(costAllImport)}</th>
                    <th data-testid={`generation-asset-data-${index}5`}>{printNewCost}</th>
                    <th data-testid={`generation-asset-data-${index}6`}>{toCurrency(costDifference)}</th>
                    <th data-testid={`generation-asset-data-${index}7`}>{toCurrency(capexOut)}</th>
                    <th data-testid={`generation-asset-data-${index}8`}>{`${assetRoi} (max)`}</th>
                  </tr>
                )
              }
              ))}
            </tbody>
          </table>}
        {contentTab === 'Graphs' &&
          <RenewableMetricsGraph
            metricsTable={assetTableBody}
          />}
      </>
    )
  }
}

export default RenewableMetrics
