// Copyright 2023 Swanbarton Limited
import { tailwindStyles } from '../constants'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const MAX_UINT32 = 2 ** 32

/**
 *
 * @returns {React.ReactElement} - Component that renders HTML input elements
 * and their associated labels for entering the site's tariff details
 */
const Tariff = ({
  scenarioData,
  setScenarioData,
  scenarioIndex
}) => {
  /**
   * Handles a change to an uncontrolled HTML input element and captures its
   * target value to state
   *
   * @callback handleStateChange
   * @param {React.BaseSyntheticEvent} event - The event triggering the change
   * @param {string} property - The property in siteTariff to be changed
   */
  const handleStateChange = (event, property) => {
    const updateScenario = [...scenarioData]
    const { target } = event

    const numberFields = ['dayRate', 'nightRate']
    if (
      numberFields.some(numberField => property === numberField) &&
      target.value !== ''
    ) {
      updateScenario[scenarioIndex].tariffData[property] = Number(target.value)
    } else {
      updateScenario[scenarioIndex].tariffData[property] = target.value
    }

    if (property === 'operationTime' && target.value !== '24/7') {
      updateScenario[scenarioIndex].tariffData.tariffType = 'fixed'
    }

    event.target.form.requestSubmit()
    setScenarioData(updateScenario)
  }

  const dayTimeString = 'time-day-' + scenarioIndex
  const nightTimeString = 'time-night-' + scenarioIndex
  const allTimeString = 'time-all-' + scenarioIndex

  const fixedTypeString = 'type-fixed-' + scenarioIndex
  const dayNightTypeString = 'type-day-night-' + scenarioIndex

  const dayRateString = 'rate-day-' + scenarioIndex
  const nightRateString = 'rate-night-' + scenarioIndex

  return (
    <section className='grid grid-cols-2 w-[530px] content-center'>
      <fieldset className={
        tailwindStyles.fieldset +
        tailwindStyles.externalBorderStyle +
        tailwindStyles.containerBackgroundStyle +
        ' w-[264px] h-[63px] grid grid-cols-[auto_18px_auto_18px_auto_18px] mt-[6px] justify-start pb-3'
      }
      >
        <legend className={tailwindStyles.label}>Site operation time:</legend>
        <label
          className={tailwindStyles.label}
          id={'label-' + dayTimeString}
          data-testid={'label-' + dayTimeString}
          htmlFor={'input-' + dayTimeString}
        > Day
        </label>
        <input
          className={tailwindStyles.inputRadio + tailwindStyles.externalBorderStyle + tailwindStyles.checkRadioStyle}
          type='radio'
          id={'input-' + dayTimeString}
          data-testid={'input-' + dayTimeString}
          name={'input-' + dayTimeString}
          value='day'
          checked={scenarioData[scenarioIndex].tariffData.operationTime === 'day'}
          onChange={(event) => handleStateChange(event, 'operationTime')}
        />
        <label
          className={tailwindStyles.label}
          id={'label-' + nightTimeString}
          data-testid={'label-' + nightTimeString}
          htmlFor={'input-' + nightTimeString}
        > Night
        </label>
        <input
          className={tailwindStyles.inputRadio + tailwindStyles.checkRadioStyle}
          type='radio'
          id={'input-' + nightTimeString}
          data-testid={'input-' + nightTimeString}
          name={'input-' + nightTimeString}
          value='night'
          checked={scenarioData[scenarioIndex].tariffData.operationTime === 'night'}
          onChange={(event) => handleStateChange(event, 'operationTime')}
        />
        <label
          className={tailwindStyles.label}
          id={'label-' + allTimeString}
          data-testid={'label-' + allTimeString}
          htmlFor={'input-' + allTimeString}
        > 24/7
        </label>
        <input
          className={tailwindStyles.inputRadio + tailwindStyles.externalBorderStyle + tailwindStyles.checkRadioStyle}
          type='radio'
          id={'input-' + allTimeString}
          data-testid={'input-' + allTimeString}
          name={'input-' + allTimeString}
          value='24/7'
          checked={scenarioData[scenarioIndex].tariffData.operationTime === '24/7'}
          onChange={(event) => handleStateChange(event, 'operationTime')}
        />
      </fieldset>
      <fieldset className={
        tailwindStyles.fieldset +
        tailwindStyles.externalBorderStyle +
        tailwindStyles.containerBackgroundStyle +
        ' w-[264px] h-[63px] grid grid-cols-[auto_18px_auto_18px] mt-[6px] justify-start pb-3'
      }
      >
        <legend className={tailwindStyles.label}>Tariff type:</legend>
        <label
          className={tailwindStyles.label}
          id={'label-' + fixedTypeString}
          data-testid={'label-' + fixedTypeString}
          htmlFor={'input-' + fixedTypeString}
        > Fixed rate
        </label>

        <input
          className={tailwindStyles.inputRadio + tailwindStyles.externalBorderStyle + tailwindStyles.checkRadioStyle}
          type='radio'
          id={'input-' + fixedTypeString}
          data-testid={'input-' + fixedTypeString}
          name={'input-' + fixedTypeString}
          value='fixed'
          checked={scenarioData[scenarioIndex].tariffData.tariffType === 'fixed'}
          onChange={(event) => handleStateChange(event, 'tariffType')}
        />
        <label
          className={tailwindStyles.label}
          id={'label-' + dayNightTypeString}
          data-testid={'label-' + dayNightTypeString}
          htmlFor={'input-' + dayNightTypeString}
        > Day/night rate
        </label>
        <Tippy
          placement='right' content='Implements 17:7 hour split between day:night tariffs.'
        >
          <input
            className={tailwindStyles.inputRadio + tailwindStyles.checkRadioStyle}
            type='radio'
            id={'input-' + dayNightTypeString}
            data-testid={'input-' + dayNightTypeString}
            name={'input-' + dayNightTypeString}
            value='dayNight'
            disabled={scenarioData[scenarioIndex].tariffData.operationTime !== '24/7'}
            checked={scenarioData[scenarioIndex].tariffData.tariffType === 'dayNight'}
            onChange={(event) => handleStateChange(event, 'tariffType')}
          />
        </Tippy>
      </fieldset>
      <fieldset className={
        tailwindStyles.fieldset +
        tailwindStyles.externalBorderStyle +
        tailwindStyles.containerBackgroundStyle +
        ' content-center mt-[1px] w-[530px] h-[100px] grid grid-cols-[150px_auto] pb-4'
      }
      >
        <legend className={tailwindStyles.label}>Rates:</legend>
        <label
          className={tailwindStyles.label}
          id={'label-' + dayRateString}
          data-testid={'label-' + dayRateString}
          htmlFor={'input-' + dayRateString}
        > Day rate (£/kWh):
        </label>
        <input
          className={tailwindStyles.inputNumber +
              tailwindStyles.internalBorderStyle +
              tailwindStyles.inputBackgroundStyle}
          type='number'
          min={0}
          max={MAX_UINT32}
          step='0.01'
          id={'input-' + dayRateString}
          data-testid={'input-' + dayRateString}
          name={'input-' + dayRateString}
          value={scenarioData[scenarioIndex].tariffData.dayRate}
          disabled={scenarioData[scenarioIndex].tariffData.operationTime === 'night'}
          onChange={(event) => handleStateChange(event, 'dayRate')}
        />
        <label
          className={tailwindStyles.label}
          id={'label-' + nightRateString}
          data-testid={'label-' + nightRateString}
          htmlFor={'input-' + nightRateString}
        > Night rate (£/kWh):
        </label>
        <input
          className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle}
          type='number'
          min={0}
          max={MAX_UINT32}
          step='0.01'
          id={'input-' + nightRateString}
          data-testid={'input-' + nightRateString}
          name={'input-' + nightRateString}
          value={scenarioData[scenarioIndex].tariffData.nightRate}
          disabled={scenarioData[scenarioIndex].tariffData.operationTime === 'day' ||
              (scenarioData[scenarioIndex].tariffData.operationTime === '24/7' &&
                  scenarioData[scenarioIndex].tariffData.tariffType === 'fixed')}
          onChange={(event) => handleStateChange(event, 'nightRate')}
        />
      </fieldset>
    </section>
  )
}

export default Tariff
