import { tailwindStyles } from '../constants'
import logo from '../Swan2024.png'
const { h1 } = tailwindStyles

const Header = () =>
  <header className={'grid grid-cols-[auto_15%] mb-10 h-[130px] w-full items-center ' +
    'bg-gradient-to-r from-[#7269A4] to-white'}
  >
    <section className='grid grid-cols-1'>
      <h1 className={h1}>
        EVIE
      </h1>
      <h3 className='text-xl text-left font-bold ml-6'>
        Energy Vector Interchange Evaluator
      </h3>
    </section>
    <img className='pr-2' src={logo} alt='Swanbarton' />
  </header>

export default Header
