/* Copyright 2023 Swanbarton Limited */

/**
 * @param {Object} siteTariff - An object describing the tariff associated with site operation
 * @returns {number} - kWh usage and cost/kWH information surrounding the vector/unit combination supplied
 */
export function calculateElectricityRate (siteTariff) {
  const baseSiteRate = siteTariff.usage !== '' && siteTariff.cost !== ''
    ? siteTariff.cost / siteTariff.usage
    : 0

  const useDayRate = siteTariff.dayRate !== ''
    ? siteTariff.dayRate
    : baseSiteRate

  const useNightRate = siteTariff.nightRate !== ''
    ? siteTariff.nightRate
    : baseSiteRate

  /**
   * cost in £/kWH of electricity obtained from the grid
   * @type {number}
   */
  let electricityRate = 0
  if (siteTariff.operationTime === 'day') {
    electricityRate = useDayRate
  } else if (siteTariff.operationTime === 'night') {
    electricityRate = useNightRate
  } else {
    if (siteTariff.tariffType === 'dayNight') {
      if (siteTariff.dayRate !== '' && siteTariff.nightRate !== '') {
        electricityRate = (17 * parseFloat(siteTariff.dayRate) + 7 * parseFloat(siteTariff.nightRate)) / 24
      }
    } else {
      electricityRate = useDayRate
    }
  }

  return electricityRate
}
