/* Copyright 2023 Swanbarton Limited */

/**
 * @typedef {Object} kWhInfo
 * @property {number} kWh - consumption of the supplied vector in kWh
 * @property {number} costPerKwh - cost per unit of consumption associated with the supplied vector in £/kWh
 */

/**
 * @param {Object} energyVector - object describing an energy vector (taken from config)
 * @param {Object} unitInputs - object describing user configured fuel
 * @param {number} consumption - total consumption associated with energyVector (default value
 * of 1 provided so that the function can be used to provide information surrounding the vector
 * that is to be converted to)
 * @returns {kWhInfo} - kWh usage and cost/kWH information surrounding the vector/unit combination supplied
 */
export function getKwhInfoFromInputs (
  energyVector,
  unitInputs,
  consumption = 1
) {
  /**
   * annual consumption associated with a single asset in kWh
   * @type {number}
   */
  let kWhOut
  /**
   * annual cost associated with a single consumption unit of fuel in £/kWh
   * @type {number}
   */
  let CostPerKwhOut
  if (energyVector !== 'Electricity') {
    if (unitInputs.unit.toLowerCase() === 'litres') {
      kWhOut = consumption * energyVector.energyDensityLitres / 1000
      CostPerKwhOut = 1000 * unitInputs.fuelCost / energyVector.energyDensityLitres
    } else if (unitInputs.unit.toLowerCase() === 'kg') {
      kWhOut = consumption * energyVector.energyDensityKg / 1000
      CostPerKwhOut = 1000 * unitInputs.fuelCost / energyVector.energyDensityKg
    } else {
      kWhOut = consumption
      CostPerKwhOut = unitInputs.fuelCost
    }
  } else {
    kWhOut = consumption
  }

  return {
    kWh: kWhOut,
    costPerKwh: CostPerKwhOut
  }
}
