/* Copyright 2023 Swanbarton Limited */
import { Children } from 'react'
import { tailwindStyles } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const MAX_CHARS = 32
const MAX_UINT32 = 2 ** 32

/**
 * Implements function that returns HTML elements for each required input field
 * associated with a site consumption asset
 *
 * @returns {JSX.Element} - returns form section representing a single consumption asset
 *
 */

function ConsumptionAsset ({
  asset,
  index,
  handleChange,
  vectors,
  scenarioData,
  setScenarioData,
  scenarioIndex,
  configurableVectors
}) {
  const assetNameString = 'assetName-' + index.toString() + '-' + scenarioIndex
  const quantityString = 'quantity-' + index.toString() + '-' + scenarioIndex
  const currentVectorString = 'current-vector-' + index.toString() + '-' + scenarioIndex
  const currentEfficiencyString = 'current-efficiency-' + index.toString() + '-' + scenarioIndex
  const annualConsumptionString = 'annual-con-' + index.toString() + '-' + scenarioIndex
  const toConvertString = 'check-convert-' + index.toString() + '-' + scenarioIndex
  const newVectorString = 'new-vector-' + index.toString() + '-' + scenarioIndex
  const newEfficiencyString = 'new-efficiency-' + index.toString() + '-' + scenarioIndex
  const capexString = 'capex-' + index.toString() + '-' + scenarioIndex

  const fuelNames = vectors.map((v) => v.fuelName)

  function returnUnitText (fuelNameIn) {
    if (fuelNameIn !== 'Electricity') {
      const vectorFromUser = configurableVectors.find(v => v.fuelName === fuelNameIn)
      return vectorFromUser.unit
    } else {
      return 'kWh'
    }
  }

  return (
    <section className='grid grid-cols-[200px_auto] row-start-1 items-center'>
      <label
        className={tailwindStyles.conAssets.label}
        id={'label-' + assetNameString}
        data-testid={'label-' + assetNameString}
        htmlFor={assetNameString}
      >
        Name
      </label>
      <input
        className={
          tailwindStyles.conAssets.inputText +
          tailwindStyles.internalBorderStyle +
          tailwindStyles.inputBackgroundStyle +
          ' mt-[7px]'
        }
        type='text'
        id={assetNameString}
        data-testid={assetNameString}
        maxLength={MAX_CHARS}
        onChange={(event) => handleChange(event, 'assetName', index)}
        value={asset.assetName}
      />

      <label
        className={tailwindStyles.conAssets.label}
        id={'label-' + quantityString}
        data-testid={'label-' + quantityString}
        htmlFor={quantityString}
      >
        Quantity
      </label>
      <input
        className={
          tailwindStyles.conAssets.inputNumber +
          tailwindStyles.internalBorderStyle +
          tailwindStyles.inputBackgroundStyle
        }
        type='number'
        id={quantityString}
        data-testid={quantityString}
        min={0}
        max={MAX_UINT32}
        onChange={(event) => handleChange(event, 'quantity', index)}
        onClick={(event) => handleChange(event, 'quantity', index)}
        value={String(asset.quantity)}
      />

      <fieldset
        className='grid grid-cols-[200px_auto_94px_62px] col-span-2'
      >
        <label
          className={tailwindStyles.conAssets.label}
          id={'label-' + currentVectorString}
          data-testid={'label-' + currentVectorString}
          htmlFor={currentVectorString}
        >
          Current vector
        </label>
        <select
          className={
            'text-sm m-0.5 px-0.5 py-0' +
            tailwindStyles.internalBorderStyle +
            tailwindStyles.inputBackgroundStyle
          }
          id={currentVectorString}
          data-testid={currentVectorString}
          onChange={(event) => handleChange(event, 'currentVector', index)}
          value={asset.currentVector}
        >
          {Children.toArray(fuelNames.map((optionText) => {
            return <option value={optionText}>{capitaliseFirstLetter(optionText)}</option>
          }))}
          <option value='Electricity'>Electricity</option>
        </select>

        <label
          className='text-sm m-0.5 px-1 py-0.5 text-white'
          id={'label-' + currentEfficiencyString}
          data-testid={'label-' + currentEfficiencyString}
          htmlFor={currentEfficiencyString}
        >
          Efficiency (%)
        </label>
        <Tippy
          placement='right' content={'Efficiency associated with the asset\'s consumption of the currently configured' +
          ' fuel vector.'}
        >
          <input
            className={
              tailwindStyles.conAssets.inputNumber +
              tailwindStyles.internalBorderStyle +
              tailwindStyles.inputBackgroundStyle
            }
            type='number'
            id={currentEfficiencyString}
            data-testid={currentEfficiencyString}
            min={1}
            max={100}
            onChange={(event) => handleChange(event, 'currentEfficiency', index)}
            onClick={(event) => handleChange(event, 'currentEfficiency', index)}
            value={String(asset.currentEfficiency)}
          />
        </Tippy>
      </fieldset>

      <label
        className={tailwindStyles.conAssets.label}
        id={'label-' + annualConsumptionString}
        data-testid={'label-' + annualConsumptionString}
        htmlFor={annualConsumptionString}
      >
        Annual consumption ({returnUnitText(asset.currentVector)})
      </label>
      <input
        className={
          tailwindStyles.conAssets.inputNumber +
          tailwindStyles.internalBorderStyle +
          tailwindStyles.inputBackgroundStyle
        }
        type='number'
        id={annualConsumptionString}
        data-testid={annualConsumptionString}
        min={0}
        max={MAX_UINT32}
        onChange={(event) => handleChange(event, 'annualConsumption', index)}
        onClick={(event) => handleChange(event, 'annualConsumption', index)}
        value={asset.annualConsumption}
      />

      <label
        className={tailwindStyles.conAssets.label}
        data-testid={'label-' + toConvertString}
        htmlFor={toConvertString}
      >
        Convert to new vector
      </label>
      <section className='justify-start mt-[3px]'>
        <input
          className={'ml-[1px]' + tailwindStyles.checkRadioStyle}
          checked={scenarioData[scenarioIndex].consumptionData[index].toConvert}
          type='checkbox'
          id={toConvertString}
          data-testid={toConvertString}
          onChange={() => {
            const updateScenario = [...scenarioData]
            updateScenario[scenarioIndex].consumptionData[index].toConvert =
                !updateScenario[scenarioIndex].consumptionData[index].toConvert
            setScenarioData(updateScenario)
          }}
        />
      </section>

      <fieldset
        className='grid grid-cols-[200px_auto_94px_62px] col-span-2'
      >
        <label
          className={tailwindStyles.conAssets.label}
          id={'label-' + newVectorString}
          data-testid={'label-' + newVectorString}
          htmlFor={newVectorString}
        >
          Convert to
        </label>
        <select
          className={
            'text-sm m-0.5 px-0.5 py-0' +
            tailwindStyles.internalBorderStyle +
            tailwindStyles.inputBackgroundStyle
        }
          id={newVectorString}
          data-testid={newVectorString}
          onChange={(event) => handleChange(event, 'newVector', index)}
          value={asset.newVector}
          disabled={!scenarioData[scenarioIndex].consumptionData[index].toConvert}
        >
          {Children.toArray(fuelNames.map((optionText) => {
            return <option value={optionText}>{capitaliseFirstLetter(optionText)}</option>
          }))}
          <option value='Electricity'>Electricity</option>
        </select>

        <label
          className='text-sm m-0.5 px-1 py-0.5 text-white'
          id={'label-' + newEfficiencyString}
          data-testid={'label-' + newEfficiencyString}
          htmlFor={newEfficiencyString}
        >
          Efficiency (%)
        </label>
        <Tippy
          placement='right' content={'Efficiency associated with the asset\'s consumption of the fuel vector it is' +
          ' being converted to.'}
        >
          <input
            className={
              tailwindStyles.conAssets.inputNumber +
              tailwindStyles.internalBorderStyle +
              tailwindStyles.inputBackgroundStyle
            }
            type='number'
            id={newEfficiencyString}
            data-testid={newEfficiencyString}
            min={1}
            max={100}
            onChange={(event) => handleChange(event, 'newEfficiency', index)}
            onClick={(event) => handleChange(event, 'newEfficiency', index)}
            value={String(asset.newEfficiency)}
            disabled={!scenarioData[scenarioIndex].consumptionData[index].toConvert}
          />
        </Tippy>
      </fieldset>

      <label
        className={tailwindStyles.conAssets.label}
        id={'label-' + capexString}
        data-testid={'label-' + capexString}
        htmlFor={capexString}
      >
        Capital expenditure (GBP)
      </label>
      <Tippy
        placement='right'
        content='Initial capital investment associated with fuel vector conversion of a single unit of the configured asset.'
      >
        <input
          className={
            tailwindStyles.conAssets.inputText +
            tailwindStyles.internalBorderStyle +
            tailwindStyles.inputBackgroundStyle +
            ' mt-[7px]'
          }
          type='number'
          id={capexString}
          data-testid={capexString}
          min={0}
          max={MAX_UINT32}
          onChange={(event) => handleChange(event, 'capex', index)}
          value={asset.capex}
          disabled={!scenarioData[scenarioIndex].consumptionData[index].toConvert}
        />
      </Tippy>
    </section>
  )
}

export default ConsumptionAsset
