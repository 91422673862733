/* Copyright 2023 Swanbarton Limited */
import { Children } from 'react'
import { tailwindStyles } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'
import { returnVectorTooltip } from '../utils/returnVectorTooltip'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const MAX_UINT32 = 2 ** 32

/**
 *
 * @returns {React.ReactElement} - Component that renders HTML input elements and their associated labels
 * for entering the site's fuel costs (with appropriate units)
 * @constructor
 */
const FuelConfiguration = ({
  energyVectors,
  scenarioData,
  setScenarioData,
  scenarioIndex
}) => {
  const handleChange = (event, property, index) => {
    const updateScenario = [...scenarioData]
    const { target } = event

    if (
      ['fuelCost', 'storageDays'].includes(property) &&
      target.value !== ''
    ) {
      updateScenario[scenarioIndex].vectorData[index][property] = Number(target.value)
    } else {
      updateScenario[scenarioIndex].vectorData[index][property] = target.value
    }

    event.target.form.requestSubmit()
    setScenarioData(updateScenario)
  }

  function returnUnitOptions (fuelNameIn) {
    const vectorFromConfig = energyVectors.find(v => v.fuelName === fuelNameIn)
    const optionsOut = ['kWh']
    if (vectorFromConfig.energyDensityLitres) {
      optionsOut.push('Litres')
    }
    if (vectorFromConfig.energyDensityKg) {
      optionsOut.push('kg')
    }
    return optionsOut
  }

  return (
    <section className='flex flex-wrap m-0.5 py-0.5 mb-5 pb-2'>
      {Children.toArray(scenarioData[scenarioIndex].vectorData?.map((vector, index) => {
        return (
          <Tippy content={returnVectorTooltip(vector.fuelName, energyVectors)}>
            <section className={
                tailwindStyles.externalBorderStyle +
                tailwindStyles.containerBackgroundStyle
              }
            >
              <fieldset className='mb-4 mr-5 grid grid-cols-[70px_auto_150px_auto] w-[508px]'>
                <legend className={tailwindStyles.label + ' font-bold'}>
                  {capitaliseFirstLetter(vector.fuelName)}
                </legend>

                <label
                  className={tailwindStyles.label + ' ml-8'}
                  id={'label-configure-units-' + vector.fuelName + '-' + scenarioIndex}
                  data-testid={'label-configure-units-' + vector.fuelName + '-' + scenarioIndex}
                  htmlFor={'configure-units-' + vector.fuelName + '-' + scenarioIndex}
                >
                  Units
                </label>
                <select
                  className={
                      tailwindStyles.inputSelect +
                      tailwindStyles.inputBackgroundStyle +
                      tailwindStyles.internalBorderStyle + ' ml-2'
                    }
                  id={'configure-units-' + vector.fuelName + '-' + scenarioIndex}
                  data-testid={'configure-units-' + vector.fuelName + '-' + scenarioIndex}
                  name={'configure-units-' + vector.fuelName + '-' + scenarioIndex}
                  value={vector.unit}
                  onChange={(event) => handleChange(event, 'unit', index)}
                >
                  {Children.toArray(returnUnitOptions(vector.fuelName)?.map((optionText) => {
                    return (
                      <option
                        key={'option-unit-' + optionText + '-' + scenarioIndex}
                        value={optionText}
                      >{optionText}
                      </option>
                    )
                  }))}
                </select>

                <label
                  className={tailwindStyles.label + ' ml-5'}
                  id={'label-configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                  data-testid={'label-configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                  htmlFor={'configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                >
                  Cost (£/unit)
                </label>
                <input
                  className={
                      tailwindStyles.inputSelect +
                      tailwindStyles.internalBorderStyle +
                      tailwindStyles.inputBackgroundStyle + ' ml-2'
                    }
                  type='number'
                  min={0}
                  max={MAX_UINT32}
                  step={0.01}
                  id={'configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                  data-testid={'configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                  name={'configure-cost-' + vector.fuelName + '-' + scenarioIndex}
                  value={vector.fuelCost}
                  onChange={(event) => handleChange(event, 'fuelCost', index)}
                  onClick={(event) => handleChange(event, 'fuelCost', index)}
                />

                <label
                  className={tailwindStyles.label + ' col-start-3 ml-5'}
                  id={'label-storage-days-' + vector.fuelName + '-' + scenarioIndex}
                  data-testid={'label-storage-days-' + vector.fuelName + '-' + scenarioIndex}
                  htmlFor={'storage-days-' + vector.fuelName + '-' + scenarioIndex}
                >
                  Storage time (days)
                </label>
                <Tippy
                  placement='right'
                  content='Maximum numbers of days storage available for specified fuel vector.'
                >
                  <input
                    className={
                        tailwindStyles.inputSelect +
                        tailwindStyles.internalBorderStyle +
                        tailwindStyles.inputBackgroundStyle + ' ml-2'
                      }
                    type='number'
                    min={1}
                    max={365}
                    step={0.5}
                    id={'storage-days-' + vector.fuelName + '-' + scenarioIndex}
                    data-testid={'storage-days-' + vector.fuelName + '-' + scenarioIndex}
                    name={'storage-days-' + vector.fuelName + '-' + scenarioIndex}
                    value={vector.storageDays}
                    onChange={(event) => handleChange(event, 'storageDays', index)}
                    onClick={(event) => handleChange(event, 'storageDays', index)}
                  />
                </Tippy>
              </fieldset>
            </section>
          </Tippy>
        )
      }
      ))}
    </section>
  )
}

export default FuelConfiguration
