/* Copyright 2023 Swanbarton Limited */
import Plot from 'react-plotly.js'
import {graphColours, tailwindStyles, vectorColors} from '../constants'
import { useContext } from 'react'
import { MetricsContext } from './App'

const ScenarioGraph = ({
  header,
  metricToPlot,
  emissionsIndex,
  costIndex,
  scenarioData
}) => {
  let shouldShowPlotEmissions = false
  let shouldShowPlotCost = false

  const { metrics } = useContext(MetricsContext)

  const plotMetrics = { ...metrics.current }

  const plotEmissions = []
  const plotCosts = []

  const scenarioNames = []
  plotMetrics.scenarioMetrics.forEach((scData, scIndex) => {
    const scenarioName = scenarioData[scIndex].nameData

    let scenarioLabel = scenarioName
    let scNameIteration = 2
    while (scenarioNames.includes(scenarioLabel)) {
      scenarioLabel = scenarioName + ` (${scNameIteration})`
      scNameIteration += 1
    }
    scenarioNames.push(scenarioLabel)

    plotEmissions.push(
      { x: [], y: [], marker: { color: vectorColors[scIndex].scopeOne }, type: 'bar', name: scenarioLabel }
    )
    plotCosts.push(
      { x: [], y: [], marker: { color: vectorColors[scIndex].scopeTwo }, type: 'bar', name: scenarioLabel }
    )
    scData[metricToPlot].forEach((asset, index) => {
      if ((index > 0) && (asset[0] !== 'Total')) {
        const assetName = asset[0]

        let labelName = assetName
        let nameIteration = 2
        while (plotEmissions[scIndex].x.includes(labelName)) {
          labelName = assetName + ` (${nameIteration})`
          nameIteration += 1
        }
        plotEmissions[scIndex].x.push(labelName)
        plotCosts[scIndex].x.push(labelName)

        const assetEmm = asset[emissionsIndex]
        plotEmissions[scIndex].y.push(assetEmm)

        const assetCost = asset[costIndex]
        plotCosts[scIndex].y.push(assetCost)

        if (assetEmm !== 0) { shouldShowPlotEmissions = true }
        if (assetCost !== 0) { shouldShowPlotCost = true }
      }
    })
  })

  if (plotCosts.length < 2) { shouldShowPlotEmissions = false }
  if (plotEmissions.length < 2) { shouldShowPlotCost = false }

  if (shouldShowPlotCost || shouldShowPlotEmissions) {
    return (
      <>
        <h2
          className={tailwindStyles.h2}
        >{header}
        </h2>
        {shouldShowPlotEmissions &&
          <Plot
            data-testid='scenario-consumption-emissions'
            className='block'
            data={plotEmissions}
            layout={{
              title: header + ' - asset emissions comparison',
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              showlegend: true,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              autosize: true,
              margin: {
                b: 150
              },
              height: 450,
              barmode: 'group',
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Emissions (t CO₂e)'
                }
              }
            }}
            useResizeHandler
            style={
            { width: '100%', height: '100%' }
          }
          />}
        {shouldShowPlotCost &&
          <Plot
            data-testid='scenario-consumption-cost'
            className='block'
            data={plotCosts}
            layout={{
              title: header + ' - asset cost comparison',
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              showlegend: true,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              autosize: true,
              margin: {
                b: 150
              },
              height: 450,
              barmode: 'group',
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Cost (GBP)'
                }
              }
            }}
            useResizeHandler
            style={
            { width: '100%', height: '100%' }
          }
          />}
      </>
    )
  } else {
    return (
      <h4
        className={'ml-5 mt-5 ' + tailwindStyles.h4}
      >
        {'Insufficient data to display graph: ' + header + ' (Site Comparison)'}
      </h4>
    )
  }
}

export default ScenarioGraph
