/* Copyright 2023 Swanbarton Limited */

import { getKwhInfoFromInputs } from './getKwhInfoFromInputs'

/**
 * @param {Array.<Object>} consumptionAssets - An array of consumption asset objects
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * @returns {number} - Total consumption of assets that utilise hydrogen in kWh
 */
export function totalHydrogenConsumption (
  consumptionAssets,
  energyVectors,
  configurableVectors
) {
  const hydrogenVectorNames = energyVectors.filter(v => v.isHydrogen === true).map(w => w.fuelName)
  const onSiteHydrogenAssets = consumptionAssets.filter(asset =>
    (
      asset.toConvert === true && hydrogenVectorNames.includes(asset.newVector)
    ) || (
      asset.toConvert === false && hydrogenVectorNames.includes(asset.currentVector)
    )
  )

  /**
   * Total site energy consumption by hydrogen sources
   * @type {number}
   */
  let totalCurrentKwhDemand = 0
  onSiteHydrogenAssets.forEach(asset => {
    const currentEfficiencyToUse = asset.currentEfficiency || 100
    const newEfficiencyToUse = asset.newEfficiency || 100

    const currentVectorUserInputs = (asset.currentVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === asset.currentVector)

    const currentEnergyVector = (asset.currentVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === asset.currentVector)

    const totalAnnualConsumption = asset.annualConsumption * asset.quantity

    const currentKwh = Math.round(
      getKwhInfoFromInputs(
        currentEnergyVector,
        currentVectorUserInputs,
        totalAnnualConsumption
      ).kWh
    )
    if (asset.toConvert) {
      totalCurrentKwhDemand += currentKwh *
        (currentEfficiencyToUse / newEfficiencyToUse)
    } else {
      totalCurrentKwhDemand += currentKwh
    }
  })
  return (
    Math.round(
      100 * totalCurrentKwhDemand
    ) / 100
  )
}
