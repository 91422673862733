/* Copyright 2023 Swanbarton Limited */

import { Children, useContext, useEffect } from 'react'
import { getKwhInfoFromInputs } from '../utils/getKwhInfoFromInputs'
import { GRID_INTENSITY, tailwindStyles } from '../constants'
import { toCurrency } from '../utils/toCurrency'
import { calculateElectricityRate } from '../utils/calculateElectricityRate'
import { totalHydrogenConsumption } from '../utils/totalHydrogenConsumption'
import HydrogenMetricsGraph from './HydrogenMetricsGraph'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { MetricsContext } from './App'
import { convertKgToTonnes } from '../utils/convertKgToTonnes'

const TOOLTIPS = {
  overview: {
    totalConsumption: [
      'Total annual consumption across all consumption assets configured to make use of hydrogen fuel.'
    ],
    maximumFuelProduction: [
      'Maximum power output associated with fuel that can be produced using the configured hydrogen electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Peak output power × 24 × 365 × Capacity factor of electrolyser)'
    ],
    demandMet: [
      'Percentage of the total annual hydrogen fuel demand met by the configured hydrogen electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum production / Demand)'
    ],
    waterUsage: [
      'Water consumption associated with attempting to meet hydrogen fuel demand.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum production × Water requirement per kWh of fuel produced)'
    ],
    electricityUsage: [
      'Electricity consumption associated with attempting to meet hydrogen fuel demand with configured hydrogen' +
      ' electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Maximum production × Electricity requirement per kWh of fuel produced)'
    ],
    totalEmissions: [
      'CO₂ emissions associated with attempting to meet hydrogen fuel demand with configured hydrogen electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Electricity requirement per kWh of fuel produced × Grid import carbon intensity)'
    ],
    totalEmissionsSaving: [
      'Potential CO₂ emission reduction that could be achieved annually by maximising the use of hydrogen produced' +
      ' using the configured electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Sum of individual asset carbon emission reductions)'
    ],
    totalCostSaving: [
      'Potential annual GBP cost that could be saved annually by maximising the use of hydrogen produced using the ' +
      'configured electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Sum of individual asset cost reductions)'
    ],
    capex: [
      'Capital expenditure associated with the installation of the configured generation asset.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Peak power × Capital cost per kW)'
    ],
    roi: [
      'Estimated number of years required for return on investment with respect to electrolyser installation.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Capital expenditure / Potential annual cost reduction)'
    ]
  },
  assetMetrics: {
    importEmissions: [
      'Annual CO₂ emissions associated with the sole use of imported hydrogen fuel to meet asset power requirements.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Fuel consumption × Total carbon intensity of importing hydrogen fuel)'
    ],
    generationEmissions: [
      'Annual CO₂ emissions associated with attempting to meet hydrogen fuel demand of individual asset with on-site' +
      ' electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Fuel requirement × Total carbon intensity of hydrogen fuel production)'
    ],
    totalEmissions: [
      'Total annual CO₂ emissions associated with attempting to meet hydrogen fuel demand of individual asset with' +
      ' on-site electrolyser. If fuel demand can not be met, this also includes CO₂ emissions of importing the' +
      ' remaining fuel.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Fuel consumption × ',
      <br key='tip-break-2' />,
      '(Proportion of fuel imported × Total carbon intensity of importing hydrogen fuel +' +
      ' Proportion of fuel generated × Total carbon intensity of hydrogen fuel production)'
    ],
    emissionsSaving: [
      'Total annual reduction in CO₂ emissions when maximising electrolyser fuel production to meet individual asset' +
      ' demand.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Total emissions with on-site production - Total import emissions)'
    ],
    importCost: [
      'Annual GBP cost associated with the sole use of imported hydrogen fuel to meet asset power requirements.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Annual fuel consumption × Current fuel cost)'
    ],
    generationCost: [
      'Annual GBP cost associated with attempting to meet hydrogen fuel demand of individual asset with on-site' +
      ' electrolyser.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Annual fuel production electricity requirement × Grid cost per kWh + any additional operational costs)'
    ],
    totalCost: [
      'Total annual GBP cost associated with attempting to meet hydrogen fuel demand of individual asset with on-site' +
      ' electrolyser. If fuel demand can not be met, this also includes CO₂ emissions of importing the remaining fuel.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Fuel consumption × ',
      <br key='tip-break-2' />,
      '(Proportion of fuel imported × annual cost of importing hydrogen fuel +' +
      ' Proportion of fuel generated × Hydrogen fuel production costs)'
    ],
    costSaving: [
      'Total annual GBP cost saving when maximising electrolyser fuel production to meet individual asset demand.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Total annual cost of fuel with on-site production - Annual cost of fuel import)'
    ],
    capex: [
      'Capital expenditure associated with fuel vector conversion.'
    ],
    assetRoi: [
      'Estimated number of years required for return on investment with respect to asset fuel vector conversion.',
      <br key='tip-break-0' />,
      <br key='tip-break-1' />,
      '(Capital expenditure / Annual cost reduction)'
    ]
  }
}

/**
 *
 * @param {Array.<Object>} consumptionAssets - An array of consumption asset objects
 * @param {Object} electrolyser - An array of consumption asset objects
 * @param {array.<Object>} electrolysisMethods - list of all potential electrolysis methods (taken from config)
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @returns {React.ReactElement} - An HTML table describing the consumption metrics
 * @param {Object} siteTariff - An object describing the tariff associated with site operation
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * associated with the production of on-site hydrogen
 * @constructor
 */
const HydrogenMetrics = ({
  consumptionAssets,
  electrolyser,
  electrolysisMethods,
  energyVectors,
  siteTariff,
  configurableVectors,
  contentTab,
  scenarioIndex
}) => {
  const electricityRate = calculateElectricityRate(siteTariff)

  const hydrogenVectorNames = energyVectors.filter(v => v.isHydrogen === true).map(w => w.fuelName)

  const onSiteHydrogenAssets = consumptionAssets.filter(asset =>
    (
      asset.toConvert === true && hydrogenVectorNames.includes(asset.newVector)
    ) || (
      asset.toConvert === false && hydrogenVectorNames.includes(asset.currentVector)
    )
  )
  const hydrogenVectorIndices = []
  consumptionAssets.forEach(
    (asset, index) => {
      if (
        (
          asset.toConvert === true && hydrogenVectorNames.includes(asset.newVector)
        ) || (
          asset.toConvert === false && hydrogenVectorNames.includes(asset.currentVector)
        )) {
        hydrogenVectorIndices.push(index)
      }
    }
  )

  const totalCurrentKwhDemand = totalHydrogenConsumption(consumptionAssets, energyVectors, configurableVectors)

  const electrolysisMethod = electrolysisMethods.find(v => v.methodName === electrolyser.electrolyserType)

  const maxPotentialProduction = electrolyser.peakPower === '' || electrolyser.capacityFactor === ''
    ? 0
    : 365 * 24 * electrolyser.peakPower * electrolyser.capacityFactor / 100

  const totalWaterRequirement = Math.round(
    electrolysisMethod.waterRequirement * maxPotentialProduction
  )

  const totalProductionEnergy = Math.round(
    electrolysisMethod.electricityRequirement * maxPotentialProduction
  )

  const totalProductionEmissions = convertKgToTonnes(
    Math.round(GRID_INTENSITY * totalProductionEnergy), 2
  )

  /**
   * Percentage of total hydrogen consumption that could be supplied by fuel that has been generated on-site
   * @type {number}
   */
  let demandRatio
  if (maxPotentialProduction !== 0 && totalCurrentKwhDemand !== 0) {
    demandRatio = maxPotentialProduction > totalCurrentKwhDemand
      ? 1
      : maxPotentialProduction / totalCurrentKwhDemand
  } else {
    demandRatio = 0
  }

  const demandMet = (maxPotentialProduction && totalCurrentKwhDemand)
    ? (
        Math.round(100 * 100 * maxPotentialProduction / totalCurrentKwhDemand) / 100
      ).toString() + '%'
    : '0%'

  const electrolyserCapex = (electrolysisMethod.capitalCost && electrolyser.peakPower)
    ? Math.round(electrolysisMethod.capitalCost * electrolyser.peakPower)
    : 0

  const additionalOperationCost = Math.round(
    electrolysisMethod.additionalOperationCost * electrolyser.peakPower
  )

  const tableBody = onSiteHydrogenAssets.map(({
    annualConsumption,
    currentVector,
    currentEfficiency,
    newVector,
    newEfficiency,
    quantity,
    assetName,
    toConvert,
    capex
  }, index) => {
    const currentEfficiencyToUse = currentEfficiency || 100
    const newEfficiencyToUse = newEfficiency || 100

    const isVectorChanging = toConvert && currentVector !== newVector

    const currentEnergyVector = (currentVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === currentVector)

    const currentVectorUserInputs = (currentVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === currentVector)

    const newVectorUserInputs = (newVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === newVector)

    const newEnergyVector = (newVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === newVector)

    const totalAnnualConsumption = annualConsumption * quantity

    const currentKwhInfo = getKwhInfoFromInputs(
      currentEnergyVector,
      currentVectorUserInputs,
      totalAnnualConsumption
    )

    const currentKwhConsumed = currentKwhInfo.kWh

    const kwhFuelDemand = (toConvert)
      ? currentKwhConsumed * (currentEfficiencyToUse / newEfficiencyToUse)
      : currentKwhConsumed * (currentEfficiencyToUse / 100)

    const currentCostPerKwh = currentKwhInfo.costPerKwh
    const newCostPerKwh = getKwhInfoFromInputs(
      newEnergyVector,
      newVectorUserInputs
    ).costPerKwh

    /**
     * Projected cost associated with importing hydrogen to cover the energy requirements of the appropriate asset
     * @type {number}
     */
    let costAllImport
    /**
     * Projected emissions associated with using imported hydrogen to cover the energy requirements of the appropriate asset
     * @type {number}
     */
    let emissionsAllImport
    if (isVectorChanging) {
      emissionsAllImport = Math.round(
        currentKwhConsumed *
        (currentEfficiencyToUse / newEfficiencyToUse) *
        (newEnergyVector.carbonIntensityScopeOne + newEnergyVector.carbonIntensityScopeTwo)
      )
      costAllImport = kwhFuelDemand * newCostPerKwh
    } else {
      emissionsAllImport = Math.round(
        currentKwhConsumed * (currentEnergyVector.carbonIntensityScopeOne + currentEnergyVector.carbonIntensityScopeTwo)
      )
      costAllImport = kwhFuelDemand * currentCostPerKwh
    }
    /**
     * Projected cost associated with the asset when converted to newVector
     * @type {number}
     */
    let newCost
    /**
     * Projected emissions associated with the asset when converted to newVector
     * @type {number}
     */
    let newEmissions
    /**
     * Cost associated with on-site hydrogen production to fulfil energy requirement of single asset
     * @type {number}
     */
    let justFuelProductionCost = 0
    if (electrolyser.peakPower === '' || electrolyser.capacityFactor === '' || electricityRate === 0) {
      newCost = 0
    } else {
      if (maxPotentialProduction >= totalCurrentKwhDemand) {
        justFuelProductionCost = Math.round(
          kwhFuelDemand * electrolysisMethod.electricityRequirement * electricityRate +
          (additionalOperationCost * kwhFuelDemand / maxPotentialProduction)
        )
        newCost = Math.round(justFuelProductionCost)
      } else {
        justFuelProductionCost = Math.round(
          demandRatio * kwhFuelDemand * electrolysisMethod.electricityRequirement * electricityRate +
          additionalOperationCost * kwhFuelDemand * demandRatio / maxPotentialProduction
        )
        if (isVectorChanging) {
          newCost = Math.round(
            justFuelProductionCost +
            (1 - demandRatio) * kwhFuelDemand * newCostPerKwh
          )
        } else {
          newCost = Math.round(
            justFuelProductionCost +
            (1 - demandRatio) * kwhFuelDemand * currentCostPerKwh
          )
        }
      }
    }

    /**
     * Emissions associated with on-site hydrogen production that attempts to fulfil single asset energy requirement
     * @type {number}
     */
    let justFuelProductionEmissionsSingleAsset = 0
    if (electrolyser.peakPower === '' || electrolyser.capacityFactor === '') {
      newEmissions = 0
    } else {
      if (maxPotentialProduction > totalCurrentKwhDemand) {
        justFuelProductionEmissionsSingleAsset = Math.round(
          kwhFuelDemand * electrolysisMethod.electricityRequirement * GRID_INTENSITY
        )
        if (isVectorChanging) {
          newEmissions = Math.round(
            kwhFuelDemand * newEnergyVector.carbonIntensityScopeOne + justFuelProductionEmissionsSingleAsset
          )
        } else {
          newEmissions = Math.round(
            kwhFuelDemand * currentEnergyVector.carbonIntensityScopeOne + justFuelProductionEmissionsSingleAsset
          )
        }
      } else {
        justFuelProductionEmissionsSingleAsset = Math.round(
          demandRatio * kwhFuelDemand * electrolysisMethod.electricityRequirement * GRID_INTENSITY
        )
        if (isVectorChanging) {
          newEmissions = Math.round(
            (1 - demandRatio) * kwhFuelDemand * newEnergyVector.carbonIntensityScopeOne +
            (1 - demandRatio) * kwhFuelDemand * newEnergyVector.carbonIntensityScopeTwo +
            justFuelProductionEmissionsSingleAsset
          )
        } else {
          newEmissions = Math.round(
            (1 - demandRatio) * kwhFuelDemand * currentEnergyVector.carbonIntensityScopeOne +
            (1 - demandRatio) * kwhFuelDemand * currentEnergyVector.carbonIntensityScopeTwo +
            justFuelProductionEmissionsSingleAsset
          )
        }
      }
    }

    let emissionsDifference = emissionsAllImport && newEmissions
      ? emissionsAllImport - newEmissions
      : 0

    const costDifference = costAllImport && newCost
      ? costAllImport - newCost
      : 0

    const capexOut = (toConvert && capex)
      ? parseFloat(capex) * quantity
      : 0

    const assetRoi = (toConvert && capex && costDifference && costDifference > 0)
      ? Math.round(100 * capexOut / costDifference) / 100
      : 0

    emissionsAllImport = convertKgToTonnes(emissionsAllImport, 2)
    justFuelProductionEmissionsSingleAsset = convertKgToTonnes(justFuelProductionEmissionsSingleAsset, 2)
    newEmissions = convertKgToTonnes(newEmissions, 2)
    emissionsDifference = convertKgToTonnes(emissionsDifference, 2)

    return [
      assetName === '' ? `Asset ${hydrogenVectorIndices[index] + 1}` : assetName,
      emissionsAllImport,
      justFuelProductionEmissionsSingleAsset,
      newEmissions,
      emissionsDifference,
      costAllImport,
      justFuelProductionCost,
      newCost,
      costDifference,
      capexOut,
      assetRoi
    ]
  })

  let maxRoi = 0
  tableBody.forEach((row, index) => {
    if (row[row.length - 1] > maxRoi) {
      maxRoi = row[row.length - 1]
    }
  })

  if (tableBody.length > 1) {
    tableBody.push([
      'Total',
      tableBody.reduce((acc, [
        _,
        emissionsAllImport]) => acc + Math.round(emissionsAllImport), 0),
      tableBody.reduce((acc, [
        _, __,
        individualProductionEmissions]) => acc + Math.round(individualProductionEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___,
        newEmissions]) => acc + Math.round(newEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____,
        emissionsDifference]) => acc + Math.round(emissionsDifference), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____,
        costAllImport]) => acc + costAllImport, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______,
        individualProductionCost]) => acc + individualProductionCost, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______,
        newCost]) => acc + newCost, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________,
        costDifference]) => acc + costDifference, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________, _________,
        capexOut]) => acc + capexOut, 0),
      maxRoi
    ])
  }

  const emissionsSavedSum = convertKgToTonnes(
    (tableBody.length ? tableBody[tableBody.length - 1][4] : 0), 2
  )

  const costSavedSum = tableBody.length ? tableBody[tableBody.length - 1][8] : 0

  const electrolyserRoi = (electrolyserCapex && costSavedSum > 0)
    ? Math.round(100 * electrolyserCapex / costSavedSum) / 100
    : 0

  const { metrics } = useContext(MetricsContext)

  const shouldRender = electrolyser.enabledStatus === true &&
    (
      consumptionAssets.some(asset => (
        asset.toConvert === true &&
        hydrogenVectorNames.includes(asset.newVector)
      )) ||
      consumptionAssets.some(asset => (
        asset.toConvert === false &&
        hydrogenVectorNames.includes(asset.currentVector)
      ))
    )

  useEffect(() => {
    const newMetrics = { ...metrics.current }

    if (shouldRender) {
      newMetrics.scenarioMetrics[scenarioIndex].hydrogenDiff = [...tableBody]
      newMetrics.scenarioMetrics[scenarioIndex].hydrogenDiff.unshift([
        'Asset name',
        'Annual emissions from importing hydrogen fuel (t CO₂e)',
        'Emissions associated with hydrogen generation (t CO₂e)',
        'Total emissions, combined on-site hydrogen fuel generation and import (t CO₂e)',
        'Net annual emissions reduction due to use of electrolyser (t CO₂e)',
        'Annual cost, import only (GBP)',
        'Annual cost of hydrogen fuel generation',
        'Annual cost, combined on-site hydrogen fuel generation and import (GBP)',
        'Annual operational cost reduction (GBP)',
        'Capital expenditure',
        'Estimated ROI (years)'
      ])

      newMetrics.scenarioMetrics[scenarioIndex].hydrogen = [
        [
          'Total consumption utilising hydrogen (kWh)',
          'Maximum hydrogen fuel production (kWh)',
          'Demand met (%)',
          'Water used in fuel production (L)',
          'Energy used in fuel production (kWh)',
          'Total emissions from fuel production (t CO₂e)',
          'Total reduction in annual emissions due to electrolyser (t CO₂e)',
          'Total reduction in annual costs due to electrolyser (GBP)',
          'Capital expenditure (GBP)',
          'Estimated ROI (years)'
        ],
        [
          totalCurrentKwhDemand || '',
          maxPotentialProduction || '',
          demandMet,
          totalWaterRequirement || '',
          totalProductionEnergy || '',
          totalProductionEmissions || '',
          emissionsSavedSum || '',
          costSavedSum || '',
          electrolyserCapex || '',
          electrolyserRoi || ''
        ]
      ]
    } else {
      newMetrics.scenarioMetrics[scenarioIndex].hydrogen = []
      newMetrics.scenarioMetrics[scenarioIndex].hydrogenDiff = []
    }

    metrics.current = newMetrics
  }, [
    electrolyser, costSavedSum, demandMet, electrolyserCapex,
    electrolyserRoi, emissionsSavedSum, maxPotentialProduction, metrics,
    shouldRender, tableBody, totalCurrentKwhDemand, totalProductionEmissions,
    totalProductionEnergy, totalWaterRequirement
  ])

  if (shouldRender) {
    return (
      <>
        {contentTab === 'Metrics' &&
          <table
            className={tailwindStyles.table}
            data-testid='hydrogen-metrics-table'
          >
            <caption className={tailwindStyles.h3}>Hydrogen generation overview</caption>
            <thead data-testid='hydrogen-table-head'>
              <tr
                className={tailwindStyles.tableHeader}
                data-testid='hydrogen-header-row'
              >
                <Tippy content={TOOLTIPS.overview.totalConsumption}>
                  <th data-testid='hydrogen-header-col-0'>Total consumption utilising hydrogen (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.maximumFuelProduction}>
                  <th data-testid='hydrogen-header-col-1'>Maximum hydrogen fuel production (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.demandMet}>
                  <th data-testid='hydrogen-header-col-2'>Demand met (%)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.waterUsage}>
                  <th data-testid='hydrogen-header-col-3'>Water used in maximising fuel production (L)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.electricityUsage}>
                  <th data-testid='hydrogen-header-col-4'>Energy used in maximising fuel production (kWh)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.totalEmissions}>
                  <th data-testid='hydrogen-header-col-5'>Total emissions from maximising fuel production (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.totalEmissionsSaving}>
                  <th data-testid='hydrogen-header-col-6'>
                    Total reduction in annual emissions due to electrolyser (t CO₂e)
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.totalCostSaving}>
                  <th data-testid='hydrogen-header-col-7'>
                    Total reduction in annual operational costs due to electrolyser
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.capex}>
                  <th data-testid='hydrogen-header-col-8'>Capital expenditure</th>
                </Tippy>
                <Tippy content={TOOLTIPS.overview.roi}>
                  <th data-testid='hydrogen-header-col-9'>Estimated ROI (years)</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='table-body'>
              <tr
                className={tailwindStyles.tableRow}
                data-testid='hydrogen-table-row-0'
              >
                <td data-testid='hydrogen-table-data-00'>{totalCurrentKwhDemand || '-'}</td>
                <td data-testid='hydrogen-table-data-01'>{maxPotentialProduction || '-'}</td>
                <td data-testid='hydrogen-table-data-02'>{demandMet}</td>
                <td data-testid='hydrogen-table-data-03'>{totalWaterRequirement || '-'}</td>
                <td data-testid='hydrogen-table-data-04'>{totalProductionEnergy || '-'}</td>
                <td data-testid='hydrogen-table-data-05'>{totalProductionEmissions || '-'}</td>
                <td data-testid='hydrogen-table-data-06'>{emissionsSavedSum || '-'}</td>
                <td data-testid='hydrogen-table-data-07'>{toCurrency(costSavedSum)}</td>
                <td data-testid='hydrogen-table-data-08'>{toCurrency(electrolyserCapex)}</td>
                <td data-testid='hydrogen-table-data-09'>{electrolyserRoi || '-'}</td>
              </tr>
            </tbody>
          </table>}
        {contentTab === 'Metrics' &&
          <table
            className={tailwindStyles.table}
            data-testid='hydrogen-diff-table'
          >
            <caption
              className={tailwindStyles.h3}
              data-testid='caption'
            >Hydrogen asset metrics
            </caption>
            <thead data-testid='hydrogen-diff-table-head'>
              <tr
                className={tailwindStyles.tableHeader}
                data-testid='hydrogen-diff-header-row'
              >
                <th data-testid='hydrogen-diff-header-col-0'>Asset name</th>
                <Tippy content={TOOLTIPS.assetMetrics.importEmissions}>
                  <th data-testid='hydrogen-diff-header-col-1'>Annual emissions from importing hydrogen fuel (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.generationEmissions}>
                  <th data-testid='hydrogen-diff-header-col-2'>Emissions associated with hydrogen generation (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.totalEmissions}>
                  <th data-testid='hydrogen-diff-header-col-3'>
                    Total emissions, combined on-site hydrogen fuel generation and import (t CO₂e)
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.emissionsSaving}>
                  <th data-testid='hydrogen-diff-header-col-4'>
                    Net Annual emissions reduction due to use of electrolyser (t CO₂e)
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.importCost}>
                  <th data-testid='hydrogen-diff-header-col-5'>Annual cost, import only</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.generationCost}>
                  <th data-testid='hydrogen-diff-header-col-6'>Annual cost of hydrogen fuel generation</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.totalCost}>
                  <th data-testid='hydrogen-diff-header-col-7'>
                    Annual cost, combined on-site hydrogen fuel generation and import
                  </th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.costSaving}>
                  <th data-testid='hydrogen-diff-header-col-8'>Annual operational cost reduction</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.capex}>
                  <th data-testid='hydrogen-diff-header-col-9'>Capital expenditure</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetMetrics.assetRoi}>
                  <th data-testid='hydrogen-diff-header-col-A'>Estimated ROI (years)</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='hydrogen-diff-table-body'>
              {Children.toArray(tableBody.map(([
                assetName,
                emissionsAllImport,
                individualProductionEmissions,
                newEmissions,
                emissionsDifference,
                costAllImport,
                individualProductionCost,
                newCost,
                costDifference,
                capexOut,
                assetRoi
              ], index) => {
                if (assetName !== 'Total') {
                  return (
                    <tr
                      className={tailwindStyles.tableRow}
                      data-testid={`hydrogen-diff-table-row-${index}`}
                    >
                      <th data-testid={`hydrogen-diff-table-data-${index}0`}>{assetName}</th>
                      <td data-testid={`hydrogen-diff-table-data-${index}1`}>{emissionsAllImport || '-'}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}2`}>{individualProductionEmissions || '-'}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}3`}>{newEmissions || '-'}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}4`}>{emissionsDifference || '-'}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}5`}>{toCurrency(costAllImport)}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}6`}>{toCurrency(individualProductionCost)}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}7`}>{toCurrency(newCost)}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}8`}>{toCurrency(costDifference)}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}9`}>{toCurrency(capexOut)}</td>
                      <td data-testid={`hydrogen-diff-table-data-${index}A`}>{assetRoi || '-'}</td>
                    </tr>
                  )
                }
                return (
                  <tr
                    className={tailwindStyles.tableHeader}
                    data-testid={`hydrogen-diff-table-row-${index}`}
                  >
                    <th data-testid={`hydrogen-diff-table-data-${index}0`}>{assetName}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}1`}>{emissionsAllImport || '-'}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}2`}>{individualProductionEmissions || '-'}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}3`}>{newEmissions || '-'}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}4`}>{emissionsDifference || '-'}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}5`}>{toCurrency(costAllImport)}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}6`}>{toCurrency(individualProductionCost)}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}7`}>{toCurrency(newCost)}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}8`}>{toCurrency(costDifference)}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}9`}>{toCurrency(capexOut)}</th>
                    <th data-testid={`hydrogen-diff-table-data-${index}A`}>{`${assetRoi} (max)`}</th>
                  </tr>
                )
              }
              ))}
            </tbody>
          </table>}
        {contentTab === 'Graphs' &&
          <HydrogenMetricsGraph
            metricsTable={tableBody}
          />}
      </>
    )
  } else {
    return <></>
  }
}

export default HydrogenMetrics
