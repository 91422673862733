export const defaultConfig = {
  energyVectors: [
    {
      fuelName: 'LNG',
      energyDensityLitres: 6670,
      carbonIntensityScopeOne: 0.27,
      carbonIntensityScopeTwo: 0.0706,
      volumeRequirement: 0.01
    },
    {
      fuelName: 'HVO',
      energyDensityLitres: 9560,
      carbonIntensityScopeOne: 0.0204,
      carbonIntensityScopeTwo: 0.0369,
      volumeRequirement: 0.02
    },
    {
      fuelName: 'Fuel Oil',
      energyDensityLitres: 10890,
      carbonIntensityScopeOne: 0.2103,
      carbonIntensityScopeTwo: 0.0678,
      volumeRequirement: 0.03
    },
    {
      fuelName: 'Diesel',
      energyDensityLitres: 9980,
      carbonIntensityScopeOne: 0.2103,
      carbonIntensityScopeTwo: 0.0611,
      volumeRequirement: 0.04
    },
    {
      fuelName: 'Green Hydrogen',
      energyDensityLitres: 2300,
      carbonIntensityScopeOne: 0,
      carbonIntensityScopeTwo: 0.0283,
      volumeRequirement: 0.05,
      isHydrogen: true
    },
    {
      fuelName: 'Blue Hydrogen',
      energyDensityLitres: 2300,
      energyDensityKg: 2300, // This is a placeholder required such that code coverage is achieved in unit testing
      carbonIntensityScopeOne: 0,
      carbonIntensityScopeTwo: 0.0253,
      volumeRequirement: 0.06,
      isHydrogen: true
    },
    {
      fuelName: 'Green Ammonia',
      energyDensityLitres: 3600,
      carbonIntensityScopeOne: 0.144,
      carbonIntensityScopeTwo: 0.0160,
      volumeRequirement: 0.07
    },
    {
      fuelName: 'Blue Ammonia',
      energyDensityLitres: 3600,
      carbonIntensityScopeOne: 0.144,
      carbonIntensityScopeTwo: 0.1920,
      volumeRequirement: 0.08
    },
    {
      fuelName: 'Green Methanol',
      energyDensityLitres: 4300,
      carbonIntensityScopeOne: 0.2484,
      carbonIntensityScopeTwo: 0.090,
      volumeRequirement: 0.09
    },
    {
      fuelName: 'Blue Methanol',
      energyDensityLitres: 3600,
      carbonIntensityScopeOne: 0.2484,
      carbonIntensityScopeTwo: 0.0252,
      volumeRequirement: 0.10
    }
  ],
  electrolysisMethods: [
    {
      methodName: 'alkaline',
      minPower: 1,
      maxPower: 50,
      capitalCost: 5000,
      waterRequirement: 0.3,
      electricityRequirement: 1.58,
      landRequirement: 70,
      volumeRequirement: 0.02,
      additionalOperationCost: 13.46
    },
    {
      methodName: 'Proton exchange membrane',
      minPower: 10,
      maxPower: 250,
      capitalCost: 20000,
      waterRequirement: 0.28,
      electricityRequirement: 1.4,
      landRequirement: 46,
      volumeRequirement: 0.02,
      additionalOperationCost: 13.46
    }
  ],
  generationMethods: [
    {
      methodName: 'wind',
      minPower: 10,
      maxPower: 150,
      capitalCost: 20000,
      landRequirement: 100
    },
    {
      methodName: 'pv',
      minPower: 10,
      maxPower: 250,
      capitalCost: 10000,
      landRequirement: 20
    }
  ]
}

export const vectorColors = [
  { scopeOne: '#083344', scopeTwo: '#0e7490' },
  { scopeOne: '#4a044e', scopeTwo: '#a21caf' },
  { scopeOne: '#022c22', scopeTwo: '#047857' },
  { scopeOne: '#3b0764', scopeTwo: '#7e22ce' },
  { scopeOne: '#422006', scopeTwo: '#a16207' },
  { scopeOne: '#172554', scopeTwo: '#1d4ed8' },
  { scopeOne: '#451a03', scopeTwo: '#b45309' },
  { scopeOne: '#082f49', scopeTwo: '#0369a1' },
  { scopeOne: '#431407', scopeTwo: '#c2410c' },
  { scopeOne: '#042f2e', scopeTwo: '#0f766e' },
  { scopeOne: '#450a0a', scopeTwo: '#b91c1c' },
  { scopeOne: '#1e1b4b', scopeTwo: '#4338ca' },
  { scopeOne: '#042f2e', scopeTwo: '#0f766e' },
  { scopeOne: '#422006', scopeTwo: '#a16207' },
  { scopeOne: '#1c1917', scopeTwo: '#57534e' }
]
export const costColours = ['#6C0E23', '#facc15']

export const tailwindStyles = {
  externalBorderStyle: ' rounded-md border-2 border-[#433685]',
  internalBorderStyle: ' rounded-md border-2 border-[#433685]',
  checkRadioStyle: ' accent-[#231D3E]',
  // containerBackgroundStyle: ' bg-[#020617]',
  containerBackgroundStyle: ' bg-[#FFFFFF]',
  inputBackgroundStyle: ' bg-[#e0deed] disabled:bg-[#7269A4]',
  buttonBackgroundStyle: ' bg-[#e0deed] disabled:bg-[#231D3E] disabled:text-white',
  h1: 'text-7xl font-bold m-0.5 ml-5',
  h2: 'text-2xl font-bold m-0.5 px-3 py-1 text-slate-900 mt-5',
  h3: 'text-xl text-left font-bold py-1 text-slate-900 mb-2',
  h4: 'text-sm text-left py-1 text-slate-900 mb-5',
  button: 'text-sm m-0.5 py-0 text-black',
  label: 'text-sm m-0.5 px-1 py-0.5 text-black',
  inputText: 'text-sm m-0.5 px-0.5 py-0 mr-2',
  inputNumber: 'text-sm m-0.5 px-0.5 py-0',
  inputSelect: 'text-sm m-0.5 px-0.5 py-0',
  inputRadio: 'text-sm m-0.5 px-0.5 py-0',
  fieldset: 'text-sm border-2 text-sm m-1 px-3 py-1 content-center',
  table: 'm-5 border text-center text-sm font-light mb-10 text-slate-900 bg-slate-300 min-width-90',
  tableRow: 'border px-6 py-4 hover:bg-slate-500 even:bg-slate-400',
  tableHeader: 'border px-6 py-4 bg-slate-400 [&>*]:px-2',
  conAssets: {
    label: 'text-sm m-0.5 px-1 py-0.5 ml-[5px] text-black',
    inputText: 'text-sm m-0.5 px-0.5 py-0 mr-[12px]',
    inputNumber: 'text-sm m-0.5 px-0.5 py-0 invalid:border-red-600 mr-[12px]',
    inputSelect: 'text-sm m-0.5 px-0.5 py-0 mr-[12px]',
    flexSection: 'grid grid-cols-1 m-0.5 py-0.5 w-[532px] content-center',
    inputRadio: 'text-sm m-0.5 px-0.5 py-0'
  },
  generationAssetSection: 'p-2 w-[534px] ' +
    'grid grid-cols-[100px_18px_170px_auto] items-center content-start',
  dialogue: 'absolute mx-auto mt-10 rounded-md bg-[#7269A] border border-[#433685]',
  logOutButton: 'text-sm m-4 p-3 text-black' +
            ' bg-[#7269A4] disabled:bg-[#231D3E]' +
            ' rounded-b-md border-[#433685]' +
            ' col-span-2 col-end-12 h-min w-20 mt-[-40px]',
  footer: 'flex justify-between slate-[#433685] mt-[300px] h-30 grid grid-cols-[150px_75%_auto]',
  addScenario: ' text-sm py-0 text-black' +
          ' rounded-b-md border-2 border-[#433685]' +
          ' ml-5 col-span-2 mt-[-50px] p-5'
}

export const graphColours = {
  backgroundColour: '#FFFFFF',
  textColour: '#141220'
}

/** Class representing a consumption asset. */
export const DefaultAsset = class {
  /**
   * Creates a consumption asset
   *
   * @param {string} currentVector - Name of the asset's current fuel vector
   * @param {string} newVector - Name of the asset's new fuel vector
   * @param {string} assetName - Asset's name
   * @param {number} quantity - Quantity of this type of asset
   * @param {number} currentEfficiency - Efficiency of the asset using its current fuel
   * @param {string|number} annualConsumption - Asset's annual consumption of its current fuel
   * @param {number} newEfficiency - Efficiency of the asset using its new fuel
   * @param {string|number} capex - capex cost associated with vector conversion
   */
  constructor (
    currentVector,
    newVector,
    assetName = '',
    quantity = 1,
    currentEfficiency = 100,
    annualConsumption = '',
    newEfficiency = 100,
    capex = ''
  ) {
    this.assetName = assetName
    this.quantity = quantity
    this.currentVector = currentVector
    this.currentEfficiency = currentEfficiency
    this.annualConsumption = annualConsumption
    this.toConvert = false
    this.newVector = newVector
    this.newEfficiency = newEfficiency
    this.capex = capex
  }
}

export const DefaultScenario = class {
  constructor (
    energyVectors,
    generationMethods,
    electrolysisMethods,
    consumptionData = Array(START_ASSETS).fill().map(() => new DefaultAsset(
      energyVectors[0].fuelName, energyVectors[1].fuelName
    )),
    vectorData = energyVectors.map(vector => {
      return {
        fuelName: vector.fuelName,
        unit: 'kWh',
        fuelCost: 0.5,
        storageDays: 365
      }
    }),
    generationData = generationMethods.map((method) => ({
      methodName: method.methodName,
      enabledStatus: false,
      peakPower: '',
      capacityFactor: 25
    })),
    electrolyserData = {
      methodName: 'Hydrogen electrolyser',
      enabledStatus: false,
      peakPower: '',
      capacityFactor: 25,
      electrolyserType: electrolysisMethods[0].methodName,
      storageDays: 365
    },
    tariffData = {
      operationTime: 'day',
      tariffType: 'fixed',
      dayRate: '',
      nightRate: '',
      usage: '',
      cost: ''
    },
    nameData = 'Unnamed site'
  ) {
    this.consumptionData = consumptionData
    this.vectorData = vectorData
    this.generationData = generationData
    this.electrolyserData = electrolyserData
    this.tariffData = tariffData
    this.nameData = nameData
  }
}

export const headers = {
  consumption: {
    assetName: 'Asset name',
    quantity: 'Quantity',
    currentVector: 'Current vector',
    currentEfficiency: 'Current efficiency (%)',
    annualConsumption: 'Annual consumption',
    toConvert: 'Convert?',
    newVector: 'New vector',
    newEfficiency: 'New efficiency (%)',
    capex: 'Capital expenditure'
  },
  generation: {
    methodName: 'Method name',
    enabledStatus: 'Enabled?',
    peakPower: 'Peak power (kWh)',
    capacityFactor: 'Capacity factor (%)'
  },
  electrolyser: {
    methodName: 'Method name',
    enabledStatus: 'Enabled?',
    peakPower: 'Peak power (kWh)',
    capacityFactor: 'Capacity factor (%)',
    electrolyserType: 'Electrolyser type',
    storageDays: 'Storage days'
  },
  tariff: {
    operationTime: 'Operation time',
    tariffType: 'Tariff type',
    dayRate: 'Day rate (GBP)',
    nightRate: 'Night rate (GBP)',
    usage: 'Usage (kWh)',
    cost: 'Cost (GBP)'
  },
  vectors: {
    fuelName: 'Fuel name',
    unit: 'Unit',
    fuelCost: 'Cost per unit (GBP)',
    storageDays: 'Storage days'
  }
}

export const GRID_INTENSITY = 0.36
export const START_ASSETS = 1
export const START_SCENARIOS = 1
