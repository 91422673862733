/* Copyright 2023 Swanbarton Limited */

import { Children, useContext, useEffect } from 'react'
import { toCurrency } from '../utils/toCurrency'
import { GRID_INTENSITY, tailwindStyles } from '../constants'
import { calculateElectricityRate } from '../utils/calculateElectricityRate'
import { getEmissionsToDisplay } from '../utils/getEmissionsToDisplay'
import ConsumptionMetricsGraph from './ConsumptionMetricsGraph'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { MetricsContext } from './App'
import { convertKgToTonnes } from '../utils/convertKgToTonnes'

const TOOLTIPS = {
  currentScope1: [
    'Annual scope 1 (tank-to-wheel) CO₂ emissions associated with asset operation, pre-conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Annual fuel consumption × Current scope 1 carbon intensity)'
  ],
  currentScope2: [
    'Annual scope 2 (well-to-tank) CO₂ emissions associated with asset operation, pre-conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Annual fuel consumption × Current scope 2 carbon intensity)'
  ],
  currentEmissionsTotal: [
    'Total annual CO₂ emissions associated with asset operation, pre-conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Current scope 1 emissions + Current scope 2 emissions)'
  ],
  newScope1: [
    'Annual projected scope 1 (tank-to-wheel) CO₂ emissions associated with ' +
    'asset operation following conversion to new fuel vector.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected fuel consumption × Scope 1 carbon intensity of new vector)'
  ],
  newScope2: [
    'Annual projected scope 2 (well-to-tank) CO₂ emissions associated with ' +
    'asset operation following conversion to new fuel vector.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected fuel consumption × Scope 2 carbon intensity of new vector)'
  ],
  newEmissionsTotal: [
    'Projected total annual CO₂ emissions associated with asset operation ' +
    'following conversion to new fuel vector.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected scope 1 emissions + Projected scope 2 emissions)'
  ],
  emissionsSaving: [
    'Annual reduction in CO₂ emissions associated with asset fuel vector conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected total emissions - Current total emissions)'
  ],
  currentCost: [
    'Annual GBP cost associated with asset operation, pre-conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Annual fuel consumption × Current fuel cost)'
  ],
  newCost: [
    'Annual GBP cost associated with asset operation ' +
    'following conversion to new fuel vector.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected annual fuel consumption × New fuel cost)'
  ],
  costSaving: [
    'Annual GBP saving associated with asset fuel vector conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Projected cost - Current cost)'
  ],
  capex: [
    'Capital expenditure associated with fuel vector conversion.'
  ],
  assetRoi: [
    'Estimated number of years required for return on investment with respect to asset fuel vector conversion.',
    <br key='tip-break-0' />,
    <br key='tip-break-1' />,
    '(Capital expenditure / Annual cost reduction)'
  ]
}

/**
 *
 * @param {Array.<Object>} consumptionAssets - An array of consumption asset objects
 * @param {Array.<Object>} energyVectors - An array of validated energy vector objects
 * @param {Object} siteTariff - An object describing the tariff associated with site operation
 * @returns {React.ReactElement} - An HTML table describing each consumption asset's metrics
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * @constructor
 *
 */
const ConsumptionMetrics = ({
  consumptionAssets,
  energyVectors,
  siteTariff,
  configurableVectors,
  contentTab,
  scenarioData,
  scenarioIndex
}) => {
  const electricityRate = calculateElectricityRate(siteTariff)

  const tableBody = consumptionAssets.map(({
    annualConsumption,
    currentVector,
    currentEfficiency,
    newVector,
    newEfficiency,
    quantity,
    assetName,
    toConvert,
    capex
  }, index) => {
    const currentEfficiencyToUse = currentEfficiency || 100
    const newEfficiencyToUse = newEfficiency || 100

    const isVectorChanging = toConvert && currentVector !== newVector

    const currentEnergyVector = (currentVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === currentVector)

    const currentVectorUserInputs = (currentVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === currentVector)

    const newVectorUserInputs = (newVector === 'Electricity')
      ? 'Electricity'
      : configurableVectors.find(v => v.fuelName === newVector)

    const newEnergyVector = (newVector === 'Electricity')
      ? 'Electricity'
      : energyVectors.find(v => v.fuelName === newVector)

    const totalAnnualConsumption = annualConsumption * quantity

    /**
     * total annual consumption currently associated with a single asset in kWh
     * @type {number}
     */
    let currentKwhConsumed
    /**
     * Cost associated with the asset before it is converted to newVector
     * @type {number}
     */
    let currentCost
    /**
     * Scope one emissions associated with the asset before it is converted to newVector
     * @type {number}
     */
    let currentScopeOneEmissions
    /**
     * Scope two emissions associated with the asset before it is converted to newVector
     * @type {number}
     */
    let currentScopeTwoEmissions

    if (currentVector !== 'Electricity') {
      if (currentVectorUserInputs.unit.toLowerCase() === 'litres') {
        currentKwhConsumed = totalAnnualConsumption *
          (currentEnergyVector.energyDensityLitres / 1000)
      } else if (currentVectorUserInputs.unit.toLowerCase() === 'kg') {
        currentKwhConsumed = totalAnnualConsumption *
          (currentEnergyVector.energyDensityKg / 1000)
      } else {
        currentKwhConsumed = totalAnnualConsumption
      }

      currentCost = Math.round(totalAnnualConsumption * currentVectorUserInputs.fuelCost)
      currentScopeOneEmissions = Math.round(
        currentKwhConsumed * currentEnergyVector.carbonIntensityScopeOne
      )
      currentScopeTwoEmissions = Math.round(
        currentKwhConsumed * currentEnergyVector.carbonIntensityScopeTwo
      )
    } else {
      currentKwhConsumed = totalAnnualConsumption
      currentCost = Math.round(totalAnnualConsumption * electricityRate)
      currentScopeOneEmissions = 0
      currentScopeTwoEmissions = Math.round(
        totalAnnualConsumption * GRID_INTENSITY
      )
    }

    /**
     * total annual energy utilised by single asset in kWh
     * @type {number}
     */
    const currentKwh = (currentEfficiencyToUse / 100) * currentKwhConsumed
    let currentEmissions = currentScopeOneEmissions + currentScopeTwoEmissions

    /**
     * total annual consumption with the asset when converted to newVector in kWh
     * @type {number}
     */
    let newKwhConsumed
    /**
     * Projected cost associated with the asset when converted to newVector
     * @type {number}
     */
    let newCost
    /**
     * Scope one emissions associated with the asset following its conversion to newVector
     * @type {number}
     */
    let newScopeOneEmissions
    /**
     * Scope two emissions associated with the asset following its conversion to newVector
     * @type {number}
     */
    let newScopeTwoEmissions

    if (isVectorChanging) {
      newKwhConsumed = (100 / newEfficiencyToUse) * currentKwh
      if (newVector === 'Electricity') {
        newCost = Math.round(
          newKwhConsumed * electricityRate
        )
        newScopeOneEmissions = 0
        newScopeTwoEmissions = Math.round(
          newKwhConsumed * GRID_INTENSITY
        )
      } else {
        /**
         * Number of fuel units required to fulfill Wh requirement set by consumption associated with old asset
         * @type {number}
         */
        let newUnitQuantity
        if (newVectorUserInputs.unit.toLowerCase() === 'litres') {
          newUnitQuantity = 1000 * newKwhConsumed / newEnergyVector.energyDensityLitres
        } else if (newVectorUserInputs.unit.toLowerCase() === 'kg') {
          newUnitQuantity = 1000 * newKwhConsumed / newEnergyVector.energyDensityKg
        } else {
          newUnitQuantity = newKwhConsumed
        }

        newCost = Math.round(
          newUnitQuantity * newVectorUserInputs.fuelCost
        )
        newScopeOneEmissions = Math.round(
          newKwhConsumed * newEnergyVector.carbonIntensityScopeOne
        )
        newScopeTwoEmissions = Math.round(
          newKwhConsumed * newEnergyVector.carbonIntensityScopeTwo
        )
      }
    } else {
      newCost = currentCost
      newScopeOneEmissions = currentScopeOneEmissions
      newScopeTwoEmissions = currentScopeTwoEmissions
    }
    let newEmissions = newScopeOneEmissions + newScopeTwoEmissions

    let emissionsDifference = currentEmissions && newEmissions
      ? currentEmissions - newEmissions
      : 0

    const costDifference = currentCost && newCost
      ? currentCost - newCost
      : 0

    const capexOut = (toConvert && capex)
      ? parseFloat(capex) * quantity
      : 0

    const assetRoi = (toConvert && capex && costDifference && costDifference > 0)
      ? Math.round(100 * capexOut / costDifference) / 100
      : 0

    currentScopeOneEmissions = convertKgToTonnes(currentScopeOneEmissions, 2)
    currentScopeTwoEmissions = convertKgToTonnes(currentScopeTwoEmissions, 2)
    currentEmissions = convertKgToTonnes(currentEmissions, 2)
    newScopeOneEmissions = convertKgToTonnes(newScopeOneEmissions, 2)
    newScopeTwoEmissions = convertKgToTonnes(newScopeTwoEmissions, 2)
    newEmissions = convertKgToTonnes(newEmissions, 2)
    emissionsDifference = convertKgToTonnes(emissionsDifference, 2)

    return [
      assetName === '' ? `Asset ${index + 1}` : assetName,
      currentScopeOneEmissions,
      currentScopeTwoEmissions,
      currentEmissions,
      newScopeOneEmissions,
      newScopeTwoEmissions,
      newEmissions,
      emissionsDifference,
      currentCost,
      newCost,
      costDifference,
      capexOut,
      assetRoi
    ]
  })

  let maxRoi = 0
  tableBody.forEach((row, index) => {
    if (row[row.length - 1] > maxRoi) {
      maxRoi = row[row.length - 1]
    }
  })

  if (tableBody.length > 1) {
    tableBody.push([
      'Total',
      tableBody.reduce((acc, [
        _,
        currentScopeOneEmissions]) => acc + Math.round(currentScopeOneEmissions), 0),
      tableBody.reduce((acc, [
        _, __,
        currentScopeTwoEmissions]) => acc + Math.round(currentScopeTwoEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___,
        currentEmissions]) => acc + Math.round(currentEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____,
        newScopeOneEmissions]) => acc + Math.round(newScopeOneEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____,
        newScopeTwoEmissions]) => acc + Math.round(newScopeTwoEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______,
        newEmissions]) => acc + Math.round(newEmissions), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______,
        emissionsDifference]) => acc + Math.round(emissionsDifference), 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________,
        currentCost]) => acc + currentCost, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________, _________,
        newCost]) => acc + newCost, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________, _________, __________,
        costDifference]) => acc + costDifference, 0),
      tableBody.reduce((acc, [
        _, __, ___, ____, _____, ______, _______, ________, _________, __________, ___________,
        capexOut]) => acc + capexOut, 0),
      maxRoi
    ])
  }

  const { metrics } = useContext(MetricsContext)

  useEffect(() => {
    const newMetrics = { ...metrics.current }

    if (consumptionAssets.length) {
      newMetrics.scenarioMetrics[scenarioIndex].consumption = [...tableBody]
      newMetrics.scenarioMetrics[scenarioIndex].consumption.unshift([
        'Asset name',
        'Current annual Scope 1 emissions (t CO₂e)',
        'Current annual Scope 2 emissions (t CO₂e)',
        'Current total emissions (t CO₂e)',
        'Projected annual Scope 1 emissions (t CO₂e)',
        'Projected annual Scope 2 emissions (t CO₂e)',
        'Projected total emissions (t CO₂e)',
        'Net annual emissions reduction (t CO₂e)',
        'Current annual operation cost (GBP)',
        'Projected annual operation cost (GBP)',
        'Net annual operation cost reduction (GBP)',
        'Capital expenditure (GBP)',
        'Estimated ROI (years)'
      ])
    } else {
      newMetrics.scenarioMetrics[scenarioIndex].consumption = []
    }

    metrics.current = newMetrics
  }, [consumptionAssets, metrics, tableBody, scenarioData])

  if (consumptionAssets.length) {
    return (
      <>
        {contentTab === 'Metrics' &&
          <table
            className={tailwindStyles.table}
            data-testid='consumption-metrics-table'
          >
            <caption data-testid='caption' className={tailwindStyles.h3}>Consumption assets</caption>
            <thead data-testid='table-head'>
              <tr className={tailwindStyles.tableHeader} data-testid='header-row'>
                <th data-testid='header-col-0'>Asset name</th>
                <Tippy content={TOOLTIPS.currentScope1}>
                  <th data-testid='header-col-1'>Current annual Scope 1 emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.currentScope2}>
                  <th data-testid='header-col-2'>Current annual Scope 2 emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.currentEmissionsTotal}>
                  <th data-testid='header-col-3'>Current total annual emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.newScope1}>
                  <th data-testid='header-col-4'>Projected annual Scope 1 emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.newScope2}>
                  <th data-testid='header-col-5'>Projected annual Scope 2 emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.newEmissionsTotal}>
                  <th data-testid='header-col-6'>Projected annual emissions (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.emissionsSaving}>
                  <th data-testid='header-col-7'>Net annual emissions reduction (t CO₂e)</th>
                </Tippy>
                <Tippy content={TOOLTIPS.currentCost}>
                  <th data-testid='header-col-8'>Current annual operational cost</th>
                </Tippy>
                <Tippy content={TOOLTIPS.newCost}>
                  <th data-testid='header-col-9'>Projected annual operational cost</th>
                </Tippy>
                <Tippy content={TOOLTIPS.costSaving}>
                  <th data-testid='header-col-A'>Annual operational cost reduction</th>
                </Tippy>
                <Tippy content={TOOLTIPS.capex}>
                  <th data-testid='header-col-B'>Capital expenditure</th>
                </Tippy>
                <Tippy content={TOOLTIPS.assetRoi}>
                  <th data-testid='header-col-C'>Estimated ROI (years)</th>
                </Tippy>
              </tr>
            </thead>
            <tbody data-testid='table-body'>
              {Children.toArray(tableBody.map(([
                assetName,
                currentScopeOneEmissions,
                currentScopeTwoEmissions,
                currentEmissions,
                newScopeOneEmissions,
                newScopeTwoEmissions,
                newEmissions,
                emissionsDifference,
                currentCost,
                newCost,
                costDifference,
                capexOut,
                assetRoi
              ], index) => {
                if (assetName !== 'Total') {
                  const currentEmissionsDisplay = getEmissionsToDisplay(currentScopeOneEmissions, currentScopeTwoEmissions)
                  const newEmissionsDisplay = getEmissionsToDisplay(newScopeOneEmissions, newScopeTwoEmissions)

                  return (
                    <tr className={tailwindStyles.tableRow} data-testid={`table-row-${index}`}>
                      <td data-testid={`table-data-${index}0`}>{assetName}</td>
                      <td data-testid={`table-data-${index}1`}>{currentEmissionsDisplay.oneToPrint}</td>
                      <td data-testid={`table-data-${index}2`}>{currentEmissionsDisplay.twoToPrint}</td>
                      <td data-testid={`table-data-${index}3`}>{currentEmissions || '-'}</td>
                      <td data-testid={`table-data-${index}4`}>{newEmissionsDisplay.oneToPrint}</td>
                      <td data-testid={`table-data-${index}5`}>{newEmissionsDisplay.twoToPrint}</td>
                      <td data-testid={`table-data-${index}6`}>{newEmissions || '-'}</td>
                      <td data-testid={`table-data-${index}7`}>{emissionsDifference || '-'}</td>
                      <td data-testid={`table-data-${index}8`}>{toCurrency(currentCost)}</td>
                      <td data-testid={`table-data-${index}9`}>{toCurrency(newCost)}</td>
                      <td data-testid={`table-data-${index}A`}>{toCurrency(costDifference)}</td>
                      <td data-testid={`table-data-${index}B`}>{toCurrency(capexOut)}</td>
                      <td data-testid={`table-data-${index}C`}>{assetRoi || '-'}</td>
                    </tr>
                  )
                }
                return (
                  <tr className={tailwindStyles.tableHeader} data-testid={`table-row-${index}`}>
                    <th data-testid={`table-data-${index}0`}>{assetName}</th>
                    <th data-testid={`table-data-${index}1`}>{currentScopeOneEmissions}</th>
                    <th data-testid={`table-data-${index}2`}>{currentScopeTwoEmissions}</th>
                    <th data-testid={`table-data-${index}3`}>{currentEmissions}</th>
                    <th data-testid={`table-data-${index}4`}>{newScopeOneEmissions}</th>
                    <th data-testid={`table-data-${index}5`}>{newScopeTwoEmissions}</th>
                    <th data-testid={`table-data-${index}6`}>{newEmissions}</th>
                    <th data-testid={`table-data-${index}7`}>{emissionsDifference}</th>
                    <th data-testid={`table-data-${index}8`}>{toCurrency(currentCost)}</th>
                    <th data-testid={`table-data-${index}9`}>{toCurrency(newCost)}</th>
                    <th data-testid={`table-data-${index}A`}>{toCurrency(costDifference)}</th>
                    <th data-testid={`table-data-${index}B`}>{toCurrency(capexOut)}</th>
                    <th data-testid={`table-data-${index}C`}>{`${assetRoi} (max)`}</th>
                  </tr>
                )
              }
              ))}
            </tbody>
          </table>}
        {contentTab === 'Graphs' &&
          <ConsumptionMetricsGraph
            metricsTable={tableBody}
            consumptionAssets={consumptionAssets}
            energyVectors={energyVectors}
          />}
      </>
    )
  } else { return <></> }
}

export default ConsumptionMetrics
