/* Copyright 2023 Swanbarton Limited */
import Inputs from './Inputs'
import Login from './Login'
import Metrics from './Metrics'
import { Children, createContext, useRef, useState } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { DefaultAsset, DefaultScenario, START_SCENARIOS, tailwindStyles } from '../constants'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import Header from './Header'
import Footer from './Footer'
import { loadXlsx, saveXlsx } from '../utils/files'
import 'tippy.js/dist/tippy.css'
import Dialog from './Dialog'
import Menu from './Menu'
import ScenarioGraph from './ScenarioGraph'
import Tippy from '@tippyjs/react'

export const MetricsContext = createContext(null)

export const TAB_HEADERS = [
  'Fuel Costs',
  'Consumption Assets',
  'Generation Assets',
  'Land Requirements',
  'Metrics',
  'Graphs',
  'Site Comparison'
]

/**
 * @param config {Object} A validated EVIE configuration object
 * @returns {React.ReactElement} - Component that contains the EVIE app
 * @constructor
 */
const App = ({ config }) => {
  const [user] = useAuthState(auth)

  const { energyVectors, generationMethods, electrolysisMethods } = config

  const [scenarioData, setScenarioData] = useState(
    Array(START_SCENARIOS)
      .fill()
      .map(() => new DefaultScenario(
        energyVectors,
        generationMethods,
        electrolysisMethods
      ))
  )

  const [scenarioTab, setScenarioTab] = useState(0)
  const [contentTab, setContentTab] = useState('Fuel Costs')

  const blankMetrics = {
    consumption: [],
    renewables: [],
    assetTable: [],
    totalRenewables: [],
    hydrogen: [],
    hydrogenDiff: [],
    fuelStorageLandRequirements: [],
    fuelStorageLandRequirementsElectrolyser: [],
    genAssetLandRequirements: []
  }

  const [error, setError] = useState(null)
  const metrics = useRef({
    scenarioMetrics: [blankMetrics]
  })

  const onSave = (index) => {
    saveXlsx(
      scenarioData[index].consumptionData,
      scenarioData[index].generationData,
      scenarioData[index].electrolyserData,
      scenarioData[index].tariffData,
      scenarioData[index].vectorData,
      metrics.current.scenarioMetrics[index],
      scenarioData[index].nameData
    )
  }

  const onLoad = async (e, index) => {
    const file = e.target.files[0]

    let data
    try {
      data = await file.arrayBuffer()
    } catch (error) {
      e.target.value = null
      return
    }

    let parsedData
    /* Coverage skipped until the end of this function as it is difficult to
     feature test. loadXlsx and the state setters are tested individually. */
    /* istanbul ignore next */
    try {
      parsedData = loadXlsx(data, scenarioData[index].vectorData)
    } catch (error) {
      setError(error)
      e.target.value = null
      return
    }

    /* istanbul ignore next */
    const [
      newConsumption,
      newGeneration,
      newElectrolyser,
      newTariff,
      newVectorPrices
    ] = parsedData

    const updateScenario = [...scenarioData]

    /* istanbul ignore next */
    updateScenario[index].consumptionData = newConsumption
    /* istanbul ignore next */
    updateScenario[index].generationData = newGeneration
    /* istanbul ignore next */
    updateScenario[index].electrolyserData = newElectrolyser
    /* istanbul ignore next */
    updateScenario[index].tariffData = newTariff
    /* istanbul ignore next */
    updateScenario[index].vectorData = newVectorPrices
    /* istanbul ignore next */
    updateScenario[index].vectorData = newVectorPrices

    /* istanbul ignore next */
    setScenarioData(updateScenario)

    /* istanbul ignore next */
    e.target.value = null

    setScenarioTab(index)
  }

  const addScenarioButton = (maxIn) => {
    return (
      <button
        className={
          tailwindStyles.buttonBackgroundStyle +
          tailwindStyles.addScenario
        }
        id='add-scenario'
        data-testid='add-scenario'
        disabled={scenarioData.length >= maxIn}
        onClick={(event) => {
          event.preventDefault()

          const updateScenario = [...scenarioData]

          const newScenario = new DefaultScenario(
            energyVectors,
            generationMethods,
            electrolysisMethods
          )

          const lastScenario = scenarioData[scenarioData.length - 1]

          // Consumption Data
          newScenario.consumptionData.splice(0, 1)
          lastScenario.consumptionData.forEach(asset => {
            const {
              assetName,
              quantity,
              currentVector,
              currentEfficiency,
              annualConsumption,
              newVector,
              newEfficiency,
              capex
            } = asset
            newScenario.consumptionData.push(
              new DefaultAsset(
                currentVector,
                newVector,
                assetName,
                quantity,
                currentEfficiency,
                annualConsumption,
                newEfficiency,
                capex
              )
            )
          })
          lastScenario.consumptionData.forEach((asset, index) => {
            newScenario.consumptionData[index].toConvert = lastScenario.consumptionData[index].toConvert
          })

          // Vector Data
          lastScenario.vectorData.forEach((fuel, index) => {
            for (const key in fuel) {
              newScenario.vectorData[index][key] = lastScenario.vectorData[index][key]
            }
          })

          // Generation Data
          lastScenario.generationData.forEach((method, index) => {
            for (const key in method) {
              newScenario.generationData[index][key] = lastScenario.generationData[index][key]
            }
          })

          // Electrolyser Data
          for (const key in lastScenario.electrolyserData) {
            newScenario.electrolyserData[key] = lastScenario.electrolyserData[key]
          }

          // Tariff Data
          for (const key in lastScenario.tariffData) {
            newScenario.tariffData[key] = lastScenario.tariffData[key]
          }

          newScenario.nameData = lastScenario.nameData

          updateScenario.push(newScenario)

          setScenarioData(updateScenario)

          setScenarioTab(updateScenario.length - 1)

          const newMetrics = { ...metrics.current }
          newMetrics.scenarioMetrics.push(blankMetrics)
          metrics.current = newMetrics

          setContentTab(TAB_HEADERS[0])
        }}
      >
        Add new site
      </button>
    )
  }

  const minusScenarioButton = (index) => {
    return (
      <Tippy content='CAUTION: Unsaved data will be lost!' hideOnClick>
        <button
          className={
            tailwindStyles.button +
            tailwindStyles.buttonBackgroundStyle +
            ' rounded-r-md border-2 border-[#433685]' +
            ' m-4 min-w-min fit-content ml-[-6px] '
          }
          id={'remove-scenario-' + index.toString()}
          data-testid={'remove-scenario-' + index.toString()}
          onClick={(event) => {
            event.preventDefault()

            const updateScenario = [...scenarioData]
            updateScenario.splice(index, 1)
            setScenarioData(updateScenario)

            if (scenarioTab === index) {
              setScenarioTab(index - 1)
            }

            const newMetrics = { ...metrics.current }
            newMetrics.scenarioMetrics.splice(index, 1)
            metrics.current = newMetrics
          }}
        >
          Remove site
        </button>
      </Tippy>
    )
  }

  return (
    <Router>
      <Dialog
        error={error}
        setError={setError}
        message={'Error - Spreadsheet ' + String(error?.message).slice(1)}
      />
      <Header />
      <Routes>
        <Route
          path='/' element={
          user
            ? (
              <div>
                {Children.toArray(scenarioData?.map((scenario, index) => {
                  return (
                    <div>
                      <Menu
                        onSave={onSave}
                        onLoad={onLoad}
                        scenarioData={scenarioData}
                        setScenarioData={setScenarioData}
                        scenarioIndex={index}
                        addScenarioButton={addScenarioButton}
                        minusScenarioButton={minusScenarioButton}
                      />
                    </div>
                  )
                }))}
                <section
                  className='mt-[-7px] ml-2'
                >
                  {addScenarioButton(15)}
                </section>
                <fieldset
                  className='grid grid-cols-11 max-w-7xl ml-6 mb-4 mt-5'
                >
                  <label
                    className='text-m text-white mt-7'
                  >
                    Active site:
                  </label>
                  <section
                    className='col-span-8 mt-7 ml-[-20px]'
                  >
                    {Children.toArray(scenarioData?.map((scenario, index) => {
                      return (
                        <button
                          data-testid={'scenario-switch-' + index}
                          disabled={index === scenarioTab}
                          className={
                          tailwindStyles.button +
                          tailwindStyles.externalBorderStyle +
                          tailwindStyles.buttonBackgroundStyle +
                          ' px-5'
                        }
                          onClick={() => { setScenarioTab(index) }}
                        >
                          {scenarioData[index].nameData}
                        </button>
                      )
                    }))}
                  </section>
                </fieldset>
                <fieldset
                  className='grid grid-cols-7 max-w-6xl mb-[-18px]'
                >
                  {Children.toArray(TAB_HEADERS?.map((headerLabel, index) => {
                    return (
                      <button
                        data-testid={'content-switch-' + index}
                        disabled={headerLabel === contentTab}
                        value={headerLabel}
                        className={
                        tailwindStyles.button +
                        ' rounded-t-md border-2 border-[#433685]' +
                        tailwindStyles.buttonBackgroundStyle +
                        ' m-4'
                      }
                        onClick={() => { setContentTab(headerLabel) }}
                      >
                        {headerLabel}
                      </button>
                    )
                  }))}
                </fieldset>
                <section
                  className={'ml-2 pb-6 ' + tailwindStyles.externalBorderStyle}
                >
                  {Children.toArray(scenarioData?.map((scenario, index) => {
                    if (index === scenarioTab) {
                      return (
                        <Inputs
                          scenarioData={scenarioData}
                          setScenarioData={setScenarioData}
                          scenarioIndex={index}
                          energyVectors={energyVectors}
                          electrolysisMethods={electrolysisMethods}
                          contentTab={contentTab}
                        />
                      )
                    } else {
                      return (
                        <></>
                      )
                    }
                  }))}
                  <MetricsContext.Provider value={{ metrics }}>
                    {Children.toArray(scenarioData?.map((scenario, index) => {
                      if (index === scenarioTab) {
                        return (
                          <Metrics
                            configurableVectors={scenarioData[index].vectorData}
                            consumptionAssets={scenarioData[index].consumptionData}
                            electrolyser={scenarioData[index].electrolyserData}
                            electrolysisMethods={electrolysisMethods}
                            energyVectors={energyVectors}
                            generationAssets={scenarioData[index].generationData}
                            siteTariff={scenarioData[index].tariffData}
                            generationMethods={generationMethods}
                            contentTab={contentTab}
                            scenarioData={scenarioData}
                            scenarioIndex={index}
                          />
                        )
                      } else {
                        return (
                          <></>
                        )
                      }
                    }))}
                    {(contentTab === 'Site Comparison') &&
                      <>
                        <ScenarioGraph
                          header='Consumption Assets'
                          metricToPlot='consumption'
                          emissionsIndex={6}
                          costIndex={9}
                          scenarioData={scenarioData}
                        />
                        <ScenarioGraph
                          header='Hydrogen Utilising Assets'
                          metricToPlot='hydrogenDiff'
                          emissionsIndex={3}
                          costIndex={7}
                          scenarioData={scenarioData}
                        />
                        <ScenarioGraph
                          header='Electricity Utilising Assets'
                          metricToPlot='assetTable'
                          emissionsIndex={2}
                          costIndex={5}
                          scenarioData={scenarioData}
                        />
                      </>}
                  </MetricsContext.Provider>
                </section>
              </div>
              )
            : <Navigate to='/login' />
        }
        />
        <Route path='/login' element={<Login />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
