/* Copyright 2023 Swanbarton Limited */

/**
 * @param {number} scopeOne - Scope one emissions in kg CO₂/kWh
 * @param {number} scopeTwo - Scope two emissions in kg CO₂/kWh
 * @returns {Object} - kWh usage and cost/kWH information surrounding the vector/unit combination supplied
 */
export function getEmissionsToDisplay (
  scopeOne,
  scopeTwo
) {
  let oneToPrint = '-'
  let twoToPrint = '-'
  if (scopeOne || scopeTwo) {
    if (scopeOne) {
      oneToPrint = scopeOne.toString()
    } else {
      oneToPrint = '0'
    }
    if (scopeTwo) {
      twoToPrint = scopeTwo.toString()
    } else {
      twoToPrint = '0'
    }
  }
  return {
    oneToPrint,
    twoToPrint
  }
}
