/* Copyright 2023 Swanbarton Limited */

/**
 * @param {number} num - Number to convert
 * @returns {string} - String representation of the number in whole pounds sterling
 */
export function toCurrency (num) {
  if (num === 0) return '-'
  return new Intl.NumberFormat(
    'en-GB',
    {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: 0
    }).format(num)
}
