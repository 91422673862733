/* Copyright 2023 Swanbarton Limited */

/**
   * function that returns a string with the first letter capitalised
   *
   * @param {string} stringIn - string to be capitalised
   * @returns {string} - input string with the first letter capitalised
   */
export function capitaliseFirstLetter (stringIn) {
  return stringIn.charAt(0).toUpperCase() + stringIn.slice(1)
}
