/* Copyright 2023 Swanbarton Limited */

/**
 * @typedef {Object} usefulInfo
 * @property {number} annualConsumption - consumption of specified fuel vector in units configured by the user
 * @property {number} currentConsumptionKwh - consumption of the original vector in kWh
 * @property {number} newConsumptionKwh - consumption of the converted vector in kWh
 * @property {number} currentVolumeRequirement - volume required to store original vector in m^3
 * @property {number} newVolumeRequirement - volume required to store converted vector in m^3
 * @property {boolean} becomesHydrogen - if
 */

/**
 *
 * @param {Object} asset - the individual asset for which input fields are generated
 * @param {string} asset.assetName - name of consumption asset
 * @param {number} asset.quantity - number of asset
 * @param {string} asset.currentVector - current energy vector of asset
 * @param {number} asset.currentEfficiency - efficiency associated with current energy vector of asset
 * @param {number} asset.annualConsumption - annual energy requirement vector
 * @param {string} asset.toConvert - dictates whether vector conversion is required
 * @param {string} asset.newVector - new energy vector to be converted to
 * @param {number} asset.newEfficiency - efficiency associated with energy vector to be converted to
 * @param {number} asset.capex - capex cost associated with vector conversion
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @returns {usefulInfo} - kWh usage and cost/kWH information surrounding the vector/unit combination supplied
 */
export function usefulConsumptionAssetValues (
  asset,
  configurableVectors,
  energyVectors
) {
  const currentEfficiencyToUse = asset.currentEfficiency || 100
  const newEfficiencyToUse = asset.newEfficiency || 100

  const annualConsumptionOut = asset.annualConsumption * asset.quantity

  const currentEnergyVector = (asset.currentVector === 'Electricity')
    ? 'Electricity'
    : energyVectors.find(v => v.fuelName === asset.currentVector)

  const currentVectorUserInputs = (asset.currentVector === 'Electricity')
    ? 'Electricity'
    : configurableVectors.find(v => v.fuelName === asset.currentVector)

  const newVectorUserInputs = (asset.newVector === 'Electricity')
    ? 'Electricity'
    : configurableVectors.find(v => v.fuelName === asset.newVector)

  const newEnergyVector = (asset.newVector === 'Electricity')
    ? 'Electricity'
    : energyVectors.find(v => v.fuelName === asset.newVector)

  const finalState = asset.toConvert
    ? newEnergyVector
    : currentEnergyVector

  const hydrogenState = finalState === 'Electricity'
    ? false
    : finalState.isHydrogen

  /**
   * total annual consumption currently associated with a single asset in kWh
   * @type {number}
   */
  let currentConsumptionKwhOut

  if (currentVectorUserInputs === 'Electricity') {
    currentConsumptionKwhOut = annualConsumptionOut
  } else {
    if (currentVectorUserInputs.unit.toLowerCase() === 'litres') {
      currentConsumptionKwhOut = annualConsumptionOut *
        (currentEnergyVector.energyDensityLitres / 1000)
    } else if (currentVectorUserInputs.unit.toLowerCase() === 'kg') {
      currentConsumptionKwhOut = annualConsumptionOut *
        (currentEnergyVector.energyDensityKg / 1000)
    } else {
      currentConsumptionKwhOut = annualConsumptionOut
    }
  }

  const newConsumptionKwhOut = (currentEfficiencyToUse / newEfficiencyToUse) * currentConsumptionKwhOut

  const currentVolumeRequirementOut = Math.round(
    100 * currentVectorUserInputs.storageDays * currentConsumptionKwhOut * currentEnergyVector.volumeRequirement / 365
  ) / 100

  const newVolumeRequirementOut = Math.round(
    100 * newVectorUserInputs.storageDays * newConsumptionKwhOut * newEnergyVector.volumeRequirement / 365
  ) / 100

  return {
    annualConsumption: annualConsumptionOut,
    currentConsumptionKwh: currentConsumptionKwhOut,
    newConsumptionKwh: newConsumptionKwhOut,
    currentVolumeRequirement: currentVolumeRequirementOut,
    newVolumeRequirement: newVolumeRequirementOut,
    becomesHydrogen: hydrogenState
  }
}
