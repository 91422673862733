import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import { validate } from './utils/validator'
import { defaultConfig } from './constants'
import './index.css'
import Dialog from './components/Dialog'

/**
 * - Renders the EVIE app using an object from an imported JSON configuration file
 * - If no config passed, renders the app with default config and a warning dialog
 * @param {Object} [config] - An object parsed from an EVIE JSON config file
 */
export const renderApp = (config = undefined) => {
  const root = ReactDOM.createRoot(document.getElementById('root'))

  // Use the default config if no config parameter passed
  if (config === undefined) {
    config = defaultConfig
  }

  root.render(
    <React.StrictMode>
      {
        // Render an error dialog if the default config has been used
        config === defaultConfig &&
          <Dialog
            error
            message='There was a problem parsing the config file, falling back to defaults'
          />
      }

      <App config={config} />
    </React.StrictMode>
  )
}

import(`./${process.env.REACT_APP_CONFIG_PATH}`)
  .then(json => {
    const parsedConfig = json.default

    if (validate(parsedConfig)) renderApp(parsedConfig)
    else renderApp()
  })
  .catch(() => renderApp())
