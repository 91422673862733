/* Copyright 2023 Swanbarton Limited */
import { Children } from 'react'
import { tailwindStyles } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const MAX_UINT32 = 2 ** 32

/**
 * @returns {React.ReactElement} - Component that renders functional HTML input elements and their associated
 * checkboxes and labels for user input of potential generation assets and their peak power outputs
 * @constructor
 */
const GenerationAssets = ({
  electrolysisMethods,
  scenarioData,
  setScenarioData,
  scenarioIndex
}) => {
  /**
   * A change handling function for the modification of objects or arrays of objects held in state
   *
   */
  const handleChange = (
    event,
    property,
    index = -1
  ) => {
    const { target } = event
    let newAsset

    const updateScenario = [...scenarioData]

    if (index === -1) {
      newAsset = updateScenario[scenarioIndex].electrolyserData
    } else {
      newAsset = updateScenario[scenarioIndex].generationData[index]
    }

    const numberFields = ['peakPower', 'capacityFactor', 'storageDays']
    if (
      numberFields.some(numberField => property === numberField) &&
      target.value !== ''
    ) {
      newAsset[property] = Number(target.value)
    } else {
      newAsset[property] = target.value
    }

    event.target.form.requestSubmit()
    setScenarioData(updateScenario)
  }

  const electrolyserString = 'electrolyser-' + scenarioIndex
  const electrolysisMethodNames = electrolysisMethods.map((v) => v.methodName)

  return (
    <>
      <h2
        className={tailwindStyles.h2}
      >Generation Assets
      </h2>
      <section
        className='flex flex-wrap ml-5 mt-3'
        data-testid={'generation-selection-section' + scenarioIndex}
      >
        <section className={
          tailwindStyles.generationAssetSection +
          tailwindStyles.externalBorderStyle +
          tailwindStyles.containerBackgroundStyle
        }
        >
          <label
            className={tailwindStyles.label + ' mt-1'}
            htmlFor={electrolyserString + '-checkbox'}
          >
            Electrolyser
          </label>
          <input
            className={tailwindStyles.checkRadioStyle}
            checked={scenarioData[scenarioIndex].electrolyserData.enabledStatus}
            type='checkbox'
            id={electrolyserString + '-checkbox'}
            data-testid={electrolyserString + '-checkbox'}
            onChange={() => {
              const updateScenario = [...scenarioData]
              updateScenario[scenarioIndex].electrolyserData.enabledStatus =
                  !updateScenario[scenarioIndex].electrolyserData.enabledStatus
              setScenarioData(updateScenario)
            }}
          />
          <label
            className={tailwindStyles.label}
            htmlFor={electrolyserString + '-peak-output'}
          >
            Peak output power (kW)
          </label>
          <Tippy
            placement='right' content='Peak power output of the generation asset.'
          >
            <input
              className={
              tailwindStyles.inputNumber +
              tailwindStyles.internalBorderStyle +
              tailwindStyles.inputBackgroundStyle
          }
              type='number'
              min={1}
              max={MAX_UINT32}
              disabled={!scenarioData[scenarioIndex].electrolyserData.enabledStatus}
              id={electrolyserString + '-peak-output'}
              data-testid={electrolyserString + '-peak-output'}
              value={scenarioData[scenarioIndex].electrolyserData.peakPower}
              onChange={
              (event) => {
                handleChange(
                  event,
                  'peakPower'
                )
              }
            }
            />
          </Tippy>
          <label
            className={tailwindStyles.label + ' col-start-3'}
            id={'label-' + electrolyserString}
            data-testid={'label-' + electrolyserString}
            htmlFor={electrolyserString + '-type-select'}
          >
            Electrolyser type
          </label>
          <select
            className={tailwindStyles.inputSelect + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle}
            id={electrolyserString + '-type-select'}
            data-testid={electrolyserString + '-type-select'}
            disabled={!scenarioData[scenarioIndex].electrolyserData.enabledStatus}
            value={scenarioData[scenarioIndex].electrolyserData.electrolyserType}
            onChange={(event) => {
              handleChange(
                event,
                'electrolyserType'
              )
            }}
          >
            {Children.toArray(electrolysisMethodNames.map((optionText) => {
              return <option value={optionText}>{capitaliseFirstLetter(optionText)}</option>
            }))}
          </select>

          <label
            className={
          tailwindStyles.label +
          ' col-start-3'
        } htmlFor={electrolyserString + '-capacity-factor'}
          >
            Capacity Factor (%)
          </label>
          <Tippy
            placement='right' content={'Scalar factor that sets expectation for power produced through annual operation' +
            ' of the generation asset.' +
            ' Calculated as average annual power output divided by peak output power'}
          >
            <input
              className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle}
              type='number'
              min={1}
              max={100}
              disabled={!scenarioData[scenarioIndex].electrolyserData.enabledStatus}
              id={electrolyserString + '-capacity-factor'}
              data-testid={electrolyserString + '-capacity-factor'}
              value={scenarioData[scenarioIndex].electrolyserData.capacityFactor}
              onChange={(event) => {
                handleChange(
                  event,
                  'capacityFactor'
                )
              }}
            />
          </Tippy>

          <label
            className={tailwindStyles.label + ' col-start-3'}
            id={'label-storage-days-' + electrolyserString}
            data-testid={'label-storage-days-' + electrolyserString}
            htmlFor={'storage-days-' + electrolyserString}
          >
            Storage time (days)
          </label>
          <Tippy
            placement='right'
            content='Maximum numbers of days storage available for produced hydrogen fuel.'
          >
            <input
              className={
                  tailwindStyles.inputSelect +
                  tailwindStyles.internalBorderStyle +
                  tailwindStyles.inputBackgroundStyle
                }
              type='number'
              min={1}
              max={365}
              step={0.5}
              disabled={!scenarioData[scenarioIndex].electrolyserData.enabledStatus}
              id={'storage-days-' + electrolyserString}
              data-testid={'storage-days-' + electrolyserString}
              name={'storage-days-' + electrolyserString}
              value={scenarioData[scenarioIndex].electrolyserData.storageDays}
              onChange={(event) => {
                handleChange(event, 'storageDays')
              }}
              onClick={(event) => {
                handleChange(event, 'storageDays')
              }}
            />
          </Tippy>
        </section>

        {Children.toArray(scenarioData[scenarioIndex].generationData.map((method, index) => {
          const labelString = method.methodName
          return (
            <section className={
                tailwindStyles.generationAssetSection +
                tailwindStyles.externalBorderStyle +
                tailwindStyles.containerBackgroundStyle +
                ' mb-5'
            }
            >
              <label
                className={tailwindStyles.label}
                htmlFor={labelString + '-checkbox'}
              >
                {capitaliseFirstLetter(labelString) + ' '}
              </label>
              <input
                type='checkbox'
                checked={scenarioData[scenarioIndex].generationData[index].enabledStatus}
                className={tailwindStyles.checkRadioStyle}
                id={labelString + '-checkbox-' + scenarioIndex}
                data-testid={labelString + '-checkbox-' + scenarioIndex}
                onChange={() => {
                  const updateScenario = [...scenarioData]
                  updateScenario[scenarioIndex].generationData[index].enabledStatus =
                      !updateScenario[scenarioIndex].generationData[index].enabledStatus
                  setScenarioData(updateScenario)
                }}
              />

              <label className={tailwindStyles.label} htmlFor={labelString + '-peak-output-' + scenarioIndex}>
                Peak output power (kW)
              </label>
              <Tippy
                placement='right' content='Peak power output of the generation asset.'
              >
                <input
                  className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle}
                  type='number'
                  min={1}
                  max={MAX_UINT32}
                  disabled={!scenarioData[scenarioIndex].generationData[index].enabledStatus}
                  id={labelString + '-peak-output-' + scenarioIndex}
                  data-testid={labelString + '-peak-output-' + scenarioIndex}
                  value={scenarioData[scenarioIndex].generationData[index].peakPower}
                  onChange={
                  (event) => {
                    handleChange(
                      event,
                      'peakPower',
                      index
                    )
                  }
                }
                />
              </Tippy>

              <label className={tailwindStyles.label + ' col-start-3'} htmlFor={labelString + '-capacity-factor-' + scenarioIndex}>
                Capacity factor (%)
              </label>
              <Tippy
                placement='right' content={'Scalar factor that sets expectation for power produced through annual' +
                ' operation of the generation asset.' +
                ' Calculated as average annual power output divided by peak output power.'}
              >
                <input
                  className={tailwindStyles.inputNumber + tailwindStyles.internalBorderStyle + tailwindStyles.inputBackgroundStyle}
                  type='number'
                  min={1}
                  max={100}
                  disabled={!scenarioData[scenarioIndex].generationData[index].enabledStatus}
                  id={labelString + '-capacity-factor-' + scenarioIndex}
                  data-testid={labelString + '-capacity-factor-' + scenarioIndex}
                  value={scenarioData[scenarioIndex].generationData[index].capacityFactor}
                  onChange={
                  (event) => {
                    handleChange(
                      event,
                      'capacityFactor',
                      index)
                  }
                }
                />
              </Tippy>
            </section>
          )
        }))}
      </section>
    </>
  )
}

export default GenerationAssets
