/* Copyright 2023 Swanbarton Limited */

/**
 * @param {string} fuelNameIn - Name of fuel for which a tooltip is to be generated
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @returns {array.<string>} - an array of strings describing stored config information surrounding fuel vector
 */
export function returnVectorTooltip (fuelNameIn, energyVectors) {
  const vectorFromConfig = energyVectors.find(v => v.fuelName === fuelNameIn)
  const tooltipOut = []
  let tipIndex = 0
  if (vectorFromConfig.energyDensityLitres) {
    tooltipOut.push(`Energy Density / Litre : ${
      Math.round(vectorFromConfig.energyDensityLitres) / 1000
    } (kWh/L)`)
  }
  if (vectorFromConfig.energyDensityKg) {
    if (tooltipOut.length > 0) {
      tooltipOut.push(<br key={`tip-break-${tipIndex}`} />)
      tipIndex += 1
    }
    tooltipOut.push(`Energy Density / kg : ${
      Math.round(vectorFromConfig.energyDensityKg) / 1000
    } (kWh/kg)`)
  }
  return tooltipOut
}
