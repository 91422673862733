import { tailwindStyles } from '../constants'
import { useSignOut } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import { useRef } from 'react'

const MIN_SCENARIOS = 1

const {
  button,
  buttonBackgroundStyle,
  externalBorderStyle
} = tailwindStyles

const Menu = ({
  onSave,
  onLoad,
  scenarioData,
  setScenarioData,
  scenarioIndex,
  minusScenarioButton
}) => {
  const fileInput = useRef(null)
  const [signOut] = useSignOut(auth)
  const navigate = useNavigate()

  /* Function excluded from coverage as it's difficult to mock and only consists
  of external libraries */
  /* istanbul ignore next */
  const onLogout = () => {
    signOut()
      .then(() => navigate('/login'))
      .catch(({ code, message }) => console.error(code, message))
  }
  return (
    <section
      className='grid grid-cols-10'
    >
      <section
        className={
          'col-span-5 ml-5 grid grid-cols-[75px_auto] px-2 py-2 content-center m-1 mt-2  ' + // ' w-[1200px] ' +
          tailwindStyles.externalBorderStyle +
          tailwindStyles.containerBackgroundStyle
        }
      >
        <label
          htmlFor='site-name'
          className={tailwindStyles.label + ' pt-[13px] max-w-2'}
        >
          Site name
        </label>
        <section
          className={
          'col-span-1 ml-5 grid grid-cols-6' +
          tailwindStyles.containerBackgroundStyle
        }
        >
          <input
            data-testid='site-name'
            id='site-name'
            value={scenarioData[scenarioIndex].nameData}
            onChange={e => {
              const updateScenario = [...scenarioData]
              updateScenario[scenarioIndex].nameData = e.target.value
              setScenarioData(updateScenario)
            }}
            className={
            tailwindStyles.inputNumber +
            tailwindStyles.internalBorderStyle +
            tailwindStyles.inputBackgroundStyle +
            ' col-span-2 my-4'
          }
          />
          <menu className='col-span-4 pl-5 grid grid-cols-2 [&>*]:min-h-min'>
            <button
              data-testid='save-button'
              className={
              button +
              buttonBackgroundStyle +
              externalBorderStyle +
              ' m-4 min-w-min min-h-30'
            }
              onClick={() => onSave(scenarioIndex)}
            >
              Save
            </button>
            <Tippy content='CAUTION: Unsaved data will be lost!' hideOnClick>
              <div
                className={
                button +
                buttonBackgroundStyle +
                externalBorderStyle +
                ' m-4 min-w-min'
              }
              >
                <button
                  data-testid='load-button'
                  className='w-[100%] h-[100%]'
                  type='file'
                  ref={fileInput}
                  onClick={() => fileInput.current.click()}
                >
                  Load
                </button>
              </div>
            </Tippy>
            <input
              data-testid='file-input'
              className='hidden'
              type='file'
              accept='.xlsx'
              ref={fileInput}
              onChange={(event) => onLoad(event, scenarioIndex)}
            />
          </menu>
        </section>
      </section>
      {(scenarioData.length > MIN_SCENARIOS) && minusScenarioButton(scenarioIndex)}
      {(scenarioIndex === 0) &&
        <button
          data-testid='logout-button'
          onClick={onLogout} className={tailwindStyles.logOutButton}
        >Log out
        </button>}
    </section>
  )
}

export default Menu
