/* Copyright 2023 Swanbarton Limited */
import { tailwindStyles } from '../constants'
import LandRequirement from './LandRequirement'
import ConsumptionMetrics from './ConsumptionMetrics'
import HydrogenMetrics from './HydrogenMetrics'
import RenewableMetrics from './RenewableMetrics'

/**
 *
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * @param {array.<Object>} consumptionAssets - An array of on-site consumption assets
 * @param {Object} electrolyser - A hydrogen electrolyser
 * @param {array.<Object>} electrolysisMethods - list of all potential electrolysis methods (taken from config)
 * @param {array.<Object>} energyVectors - list of all potential energy vectors (taken from config)
 * @param {array.<Object>} generationAssets - An array of on-site generation assets
 * @param {Object} siteTariff - An object describing the tariff associated with site operation
 * @param {array.<Object>} generationMethods - list of all potential generation methods (taken from config)
 * @returns {React.ReactElement} - Component that renders an HTML section in which
 * all output metric tables are displayed
 * @constructor
 */
const Metrics = ({
  configurableVectors,
  consumptionAssets,
  electrolyser,
  electrolysisMethods,
  energyVectors,
  generationAssets,
  siteTariff,
  generationMethods,
  contentTab,
  scenarioIndex
}) => {
  return (
    <section data-testid='metrics'>
      {(contentTab === 'Land Requirements') &&
        <LandRequirement
          consumptionAssets={consumptionAssets}
          energyVectors={energyVectors}
          siteTariff={siteTariff}
          electrolyser={electrolyser}
          electrolysisMethods={electrolysisMethods}
          configurableVectors={configurableVectors}
          generationAssets={generationAssets}
          generationMethods={generationMethods}
          scenarioIndex={scenarioIndex}
        />}
      <>
        {contentTab === 'Metrics' &&
          <h2
            className={tailwindStyles.h2}
          >Metrics
          </h2>}
        <ConsumptionMetrics
          consumptionAssets={consumptionAssets}
          energyVectors={energyVectors}
          siteTariff={siteTariff}
          configurableVectors={configurableVectors}
          contentTab={contentTab}
          scenarioIndex={scenarioIndex}
        />
        <HydrogenMetrics
          energyVectors={energyVectors}
          consumptionAssets={consumptionAssets}
          electrolysisMethods={electrolysisMethods}
          electrolyser={electrolyser}
          siteTariff={siteTariff}
          generationAssets={generationAssets}
          configurableVectors={configurableVectors}
          contentTab={contentTab}
          scenarioIndex={scenarioIndex}
        />
        <RenewableMetrics
          energyVectors={energyVectors}
          consumptionAssets={consumptionAssets}
          siteTariff={siteTariff}
          generationAssets={generationAssets}
          configurableVectors={configurableVectors}
          electrolysisMethods={electrolysisMethods}
          electrolyser={electrolyser}
          generationMethods={generationMethods}
          contentTab={contentTab}
          scenarioIndex={scenarioIndex}
        />
      </>
    </section>
  )
}

export default Metrics
