/* Copyright 2023 Swanbarton Limited */

import { Children, useContext } from 'react'
import { tailwindStyles } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'
import { totalHydrogenConsumption } from '../utils/totalHydrogenConsumption'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { usefulConsumptionAssetValues } from '../utils/usefulConsumptionAssetValues'
import { MetricsContext } from './App'

/**
 *
 * @param {Array.<Object>} consumptionAssets - An array of consumption asset objects
 * @param {Array.<Object>} energyVectors - An array of validated energy vector objects
 * @param {Object} electrolyser - An array of consumption asset objects
 * @param {array.<Object>} electrolysisMethods - list of all potential electrolysis methods (taken from config)
 * @param {array.<Object>} configurableVectors - list of all user configured options around potential energy vectors
 * @param {Array.<Object>} generationAssets - Array of potential generation methods, configured using
 * Inputs.js which draws properties from config
 * @param {array.<Object>} generationMethods - list of all potential generation methods (taken from config)
 * @returns {React.ReactElement} - One/two HTML tables describing on-site fuel storage requirements
 * @constructor
 *
 */
const LandRequirement = ({
  consumptionAssets,
  energyVectors,
  electrolyser,
  electrolysisMethods,
  configurableVectors,
  generationAssets,
  generationMethods,
  scenarioData,
  scenarioIndex
}) => {
  const fuelVolumeTable = [['Fuel vector', 'Storage requirement (m³)']]
  const hydrogenFuelVolumeTable = [['Fuel vector', 'Storage requirement (m³)']]
  const landRequirementTable = [['Generation asset', 'Land requirement (m²)']]
  const { metrics } = useContext(MetricsContext)

  consumptionAssets.forEach((asset, index) => {
    if (
      (!asset.toConvert && asset.currentVector !== 'Electricity'
      ) || (asset.toConvert && asset.newVector !== 'Electricity')
    ) {
      const assetValues = usefulConsumptionAssetValues(asset, configurableVectors, energyVectors)

      const fuelName = (asset.toConvert)
        ? asset.newVector
        : asset.currentVector

      const volumeRequired = (asset.toConvert)
        ? assetValues.newVolumeRequirement
        : assetValues.currentVolumeRequirement

      const indexToAddTo = fuelVolumeTable.findIndex(v => v[0] === fuelName)
      if (indexToAddTo !== -1) {
        fuelVolumeTable[indexToAddTo][1] += volumeRequired
      } else {
        fuelVolumeTable.push(
          [fuelName, volumeRequired]
        )
      }
    }
  })

  if (electrolyser.enabledStatus && electrolyser.peakPower !== '') {
    const totalHydrogenFuelDemand = totalHydrogenConsumption(consumptionAssets, energyVectors, configurableVectors)
    const maximumHydrogenFuelProduction = 365 * 24 * electrolyser.peakPower * electrolyser.capacityFactor / 100

    const hydrogenProductionToMeetDemand = (maximumHydrogenFuelProduction > totalHydrogenFuelDemand)
      ? totalHydrogenFuelDemand
      : maximumHydrogenFuelProduction

    const excessHydrogenFuelProduction = (maximumHydrogenFuelProduction > totalHydrogenFuelDemand)
      ? maximumHydrogenFuelProduction - totalHydrogenFuelDemand
      : 0

    const electrolysisMethod = electrolysisMethods.find(v => v.methodName === electrolyser.electrolyserType)
    const volumeRequirementPerKwh = electrolysisMethod.volumeRequirement

    const hydrogenProductionRatio = maximumHydrogenFuelProduction > totalHydrogenFuelDemand
      ? 1
      : maximumHydrogenFuelProduction / totalHydrogenFuelDemand

    consumptionAssets.forEach((asset, index) => {
      const assetValues = usefulConsumptionAssetValues(asset, configurableVectors, energyVectors)

      if (
        assetValues.becomesHydrogen
      ) {
        const assetValues = usefulConsumptionAssetValues(asset, configurableVectors, energyVectors)

        const fuelName = (asset.toConvert)
          ? asset.newVector
          : asset.currentVector

        const volumeRequired = (asset.toConvert)
          ? assetValues.newVolumeRequirement
          : assetValues.currentVolumeRequirement

        const indexToAddTo = hydrogenFuelVolumeTable.findIndex(v => v[0] === fuelName)
        if (indexToAddTo !== -1) {
          hydrogenFuelVolumeTable[indexToAddTo][1] += Math.round(
            volumeRequired * (1 - hydrogenProductionRatio) * 100) / 100
        } else {
          hydrogenFuelVolumeTable.push(
            [fuelName, Math.round(volumeRequired * (1 - hydrogenProductionRatio) * 100) / 100]
          )
        }
      }
    })

    if (hydrogenProductionToMeetDemand) {
      const fuelName = 'Generated hydrogen fuel (on-site fuel demand)'
      const volumeRequired = Math.round(
        100 * electrolyser.storageDays * hydrogenProductionToMeetDemand * volumeRequirementPerKwh / 365
      ) / 100
      hydrogenFuelVolumeTable.push(
        [fuelName, volumeRequired]
      )
    }
    if (excessHydrogenFuelProduction) {
      const fuelName = 'Generated hydrogen fuel (excess)'
      const volumeRequired = Math.round(
        100 * electrolyser.storageDays * excessHydrogenFuelProduction * volumeRequirementPerKwh / 365
      ) / 100
      hydrogenFuelVolumeTable.push(
        [fuelName, volumeRequired]
      )
    }

    const landRequirement = Math.round(
      100 * electrolyser.peakPower * electrolysisMethod.landRequirement
    ) / 100
    landRequirementTable.push([
      'Hydrogen electrolyser',
      landRequirement
    ])
  }

  const enabledGenerationAssets = generationAssets.filter(v => (v.enabledStatus === true))

  if (enabledGenerationAssets.length) {
    generationAssets.forEach(asset => {
      if (asset.enabledStatus && asset.peakPower !== '') {
        const generationMethodToUse = generationMethods.find(v => (v.methodName === asset.methodName))
        const landRequirement = Math.round(
          100 * asset.peakPower * generationMethodToUse.landRequirement
        ) / 100
        landRequirementTable.push([
          asset.methodName,
          landRequirement
        ])
      }
    })
  }

  const newMetrics = { ...metrics.current }

  if (fuelVolumeTable.length > 1) {
    newMetrics.scenarioMetrics[scenarioIndex].fuelStorageLandRequirements = [...fuelVolumeTable]
  } else {
    newMetrics.scenarioMetrics[scenarioIndex].fuelStorageLandRequirements = []
  }

  if (hydrogenFuelVolumeTable.length > 1) {
    newMetrics.scenarioMetrics[scenarioIndex].fuelStorageLandRequirementsElectrolyser = [...hydrogenFuelVolumeTable]
  } else {
    newMetrics.scenarioMetrics[scenarioIndex].fuelStorageLandRequirementsElectrolyser = []
  }

  if (landRequirementTable.length > 1) {
    newMetrics.scenarioMetrics[scenarioIndex].genAssetLandRequirements = [...landRequirementTable]
  } else {
    newMetrics.scenarioMetrics[scenarioIndex].genAssetLandRequirements = []
  }

  metrics.current = newMetrics

  return (
    <section>
      {
        fuelVolumeTable.length > 1 &&
          <div>
            <h2
              className={tailwindStyles.h2}
            >Land Requirements
            </h2>
            <h3
              data-testid='fuel-volume-caption'
              className={tailwindStyles.h3 + ' ml-5'}
            >
              Fuel storage requirements
            </h3>
            <Tippy content={
                'Volume of fuel storage required (in m³) for each fuel vector given overall annual site consumption ' +
                'and the specified number of available storage days for that fuel.'
            }
            >
              <table
                className={tailwindStyles.table}
                data-testid='fuel-volume-table'
              >
                <thead data-testid='fuel-volume-table-head'>
                  <tr className={tailwindStyles.tableHeader} data-testid='fuel-volume-header-row'>
                    {Children.toArray(fuelVolumeTable.map(([
                      fuelHeader,
                      volumeRequirement
                    ], index) => {
                      return (
                        <th data-testid={`fuel-volume-header-${index}`}>{capitaliseFirstLetter(fuelHeader)}</th>
                      )
                    }))}
                  </tr>
                </thead>
                <tbody data-testid='fuel-volume-table-body'>
                  <tr className={tailwindStyles.tableRow} data-testid='fuel-volume-table-row'>
                    {Children.toArray(fuelVolumeTable.map(([
                      fuelHeader,
                      volumeRequirement
                    ], index) => {
                      return (
                        <td data-testid={`fuel-volume-data-${index}`}>{volumeRequirement}</td>
                      )
                    }))}
                  </tr>
                </tbody>
              </table>
            </Tippy>
          </div>
      }
      {
        hydrogenFuelVolumeTable.length > 1 &&
          <div>
            <h3
              data-testid='hyd-fuel-volume-caption'
              className={tailwindStyles.h3 + ' ml-5'}
            >
              Fuel storage requirements with configured electrolyser
            </h3>
            <Tippy content={
                'Volume of hydrogen fuel storage required (in m³) for each utilised hydrogen fuel vector given ' +
                'overall annual site consumption, the specified number of available storage days for that fuel and ' +
                'the output of the configured electrolyser'
            }
            >
              <table
                className={tailwindStyles.table}
                data-testid='hyd-fuel-volume-table'
              >
                <thead data-testid='hyd-fuel-volume-table-head'>
                  <tr className={tailwindStyles.tableHeader} data-testid='hyd-fuel-volume-header-row'>
                    {Children.toArray(hydrogenFuelVolumeTable.map(([
                      fuelHeader,
                      volumeRequirement
                    ], index) => {
                      return (
                        <th data-testid={`hyd-fuel-volume-header-${index}`}>{capitaliseFirstLetter(fuelHeader)}</th>
                      )
                    }))}
                  </tr>
                </thead>
                <tbody data-testid='hyd-fuel-volume-table-body'>
                  <tr className={tailwindStyles.tableRow} data-testid='hyd-fuel-volume-table-row'>
                    {Children.toArray(hydrogenFuelVolumeTable.map(([
                      fuelHeader,
                      volumeRequirement
                    ], index) => {
                      return (
                        <td data-testid={`hyd-fuel-volume-data-${index}`}>{volumeRequirement}</td>
                      )
                    }))}
                  </tr>
                </tbody>
              </table>
            </Tippy>
          </div>
      }
      {
        landRequirementTable.length > 1 &&
          <div>
            <h3
              data-testid='land-requirement-caption'
              className={tailwindStyles.h3 + ' ml-5'}
            >
              Generation asset land requirements
            </h3>
            <Tippy content='Area required (in m²) for each configured generation asset.'>
              <table
                className={tailwindStyles.table}
                data-testid='land-requirement-table'
              >
                <thead data-testid='land-requirement-table-head'>
                  <tr className={tailwindStyles.tableHeader} data-testid='land-requirement-header-row'>
                    {Children.toArray(landRequirementTable.map(([
                      fuelHeader,
                      totalLandRequirement
                    ], index) => {
                      return (
                        <th data-testid={`land-requirement-header-${index}`}>{capitaliseFirstLetter(fuelHeader)}</th>
                      )
                    }))}
                  </tr>
                </thead>
                <tbody data-testid='land-requirement-table-body'>
                  <tr className={tailwindStyles.tableRow} data-testid='land-requirement-table-row'>
                    {Children.toArray(landRequirementTable.map(([
                      fuelHeader,
                      totalLandRequirement
                    ], index) => {
                      return (
                        <td data-testid={`land-requirement-data-${index}`}>{totalLandRequirement}</td>
                      )
                    }))}
                  </tr>
                </tbody>
              </table>
            </Tippy>
          </div>
      }
    </section>
  )
}

export default LandRequirement
