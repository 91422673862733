/* Copyright 2023 Swanbarton Limited */
import Plot from 'react-plotly.js'
import {costColours, graphColours, tailwindStyles, vectorColors} from '../constants'

const HydrogenMetricsGraph = ({
  metricsTable
}) => {
  const currentEmissionsColor = vectorColors[0].scopeOne
  const newEmissionsColor = vectorColors[0].scopeTwo

  const currentCostColor = costColours[0]
  const newCostColor = costColours[1]

  const emissionsLabelData = []
  const emissionsValueData = []

  const costLabelData = []
  const costValueData = []

  const emissionsColors = []
  const costColors = []

  const allLabels = []

  metricsTable.forEach(([
    assetName,
    emissionsAllImport,
    individualProductionEmissions,
    newEmissions,
    emissionsDifference,
    costAllImport,
    individualProductionCost,
    newCost,
    costDifference
  ]) => {
    let graphDisplayName = assetName
    let nameIteration = 2
    while (allLabels.includes(graphDisplayName)) {
      graphDisplayName = assetName + ` (${nameIteration})`
      nameIteration += 1
    }
    allLabels.push(graphDisplayName)

    if (emissionsAllImport || newEmissions) {
      if (assetName !== 'Total') {
        emissionsLabelData.push(`${graphDisplayName} - Import Only Emissions`)
        emissionsLabelData.push(`${graphDisplayName} - Emissions w/ Electrolyser`)

        emissionsValueData.push(emissionsAllImport)
        emissionsColors.push(currentEmissionsColor)

        emissionsValueData.push(newEmissions)
        emissionsColors.push(newEmissionsColor)
      }
    }

    if (costAllImport || newCost) {
      if (assetName !== 'Total') {
        costLabelData.push(`${graphDisplayName} - Import Only Cost`)
        costLabelData.push(`${graphDisplayName} - Cost w/ Electrolyser`)

        costValueData.push(costAllImport)
        costColors.push(currentCostColor)

        costValueData.push(newCost)
        costColors.push(newCostColor)
      }
    }
  })

  if (emissionsLabelData.length > 0 || costLabelData.length > 0) {
    return (
      <>
        {(emissionsLabelData.length > 0) &&
          <Plot
            data-testid='hydrogen-graph-emissions'
            className='block'
            data={[
              {
                type: 'bar',
                marker: {
                  color: emissionsColors
                },
                x: emissionsLabelData,
                y: emissionsValueData
              }
            ]}
            layout={{
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              showlegend: false,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              autosize: true,
              margin: {
                b: 80
              },
              height: 350,
              barmode: 'stack',
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Emissions (t CO₂e)'
                }
              }
            }}
            useResizeHandler
            style={
              { width: '100%', height: '100%' }
            }
          />}
        {(costLabelData.length > 0) &&
          <Plot
            data-testid='hydrogen-graph-costs'
            data={[
              {
                type: 'bar',
                marker: {
                  color: costColors
                },
                x: costLabelData,
                y: costValueData
              }
            ]}
            layout={{
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              autosize: true,
              margin: {
                b: 80
              },
              height: 300,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Cost (GBP)'
                }
              }
            }}
            useResizeHandler
            style={
              { width: '100%', height: '100%' }
            }
          />}
      </>
    )
  } else {
    return (
      <h4
        className={'ml-5 mt-5 ' + tailwindStyles.h4}
      >
        {'More data required to display hydrogen metrics graphs - ' +
          'consider adding hydrogen utilising assets and/or on-site hydrogen generation'}
      </h4>
    )
  }
}

export default HydrogenMetricsGraph
