import * as XLSX from 'xlsx'
import Ajv from 'ajv/dist/2020'
import fileSchema from '../schemas/file.schema.json'
import { headers } from '../constants'

/**
 *
 * @returns {string} - String representing today's date as `YYMMDD`
 */
const getDateStr = () => {
  const currentDate = new Date()

  const year = currentDate.getFullYear().toString().slice(-2)
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
  const day = ('0' + currentDate.getDate()).slice(-2)

  return year + month + day
}

/**
 * Saves the passed parameters to a Microsoft Excel (.xlsx) spreadsheet. If used
 * in a browser, the file will appear as a download. If used in node.js, the file
 * will be downloaded to the root path of the application.
 *
 * @param {Array<Object>} consumptionAssets - An array of consumption asset
 * objects
 * @param {Array<Object>} generationAssets - An array of renewable generation
 * asset objects
 * @param {Object} electrolyser - A hydrogen electrolyser object
 * @param {Object} siteTariff - A site tariff object
 * @param {Array<Object>} configurableVectors - An array of energy vector objects
 * @param {Object} singleScenarioMetrics - An object containing six 2D arrays as properties
 * @param {Array<string|number>} singleScenarioMetrics.consumption
 * @param {Array<string|number>} singleScenarioMetrics.renewables
 * @param {Array<string|number>} singleScenarioMetrics.assetTable
 * @param {Array<string|number>} singleScenarioMetrics.totalRenewables
 * @param {Array<string|number>} singleScenarioMetrics.hydrogen
 * @param {Array<string|number>} singleScenarioMetrics.hydrogenDiff
 * @param {Array<string|number>} singleScenarioMetrics.fuelStorageLandRequirements
 * @param {Array<string|number>} singleScenarioMetrics.fuelStorageLandRequirementsElectrolyser
 * @param {Array<string|number>} singleScenarioMetrics.genAssetLandRequirements
 * @param {string} siteName
 */
export const saveXlsx = (
  consumptionAssets,
  generationAssets,
  electrolyser,
  siteTariff,
  configurableVectors,
  singleScenarioMetrics,
  siteName
) => {
  const {
    consumption,
    renewables,
    assetTable,
    totalRenewables,
    hydrogen,
    hydrogenDiff,
    fuelStorageLandRequirements,
    fuelStorageLandRequirementsElectrolyser,
    genAssetLandRequirements
  } = singleScenarioMetrics

  const workbook = XLSX.utils.book_new();
  [
    [consumptionAssets, 'Consumption assets', headers.consumption],
    [generationAssets, 'Generation assets', headers.generation],
    [[electrolyser], 'Hydrogen electrolyser', headers.electrolyser],
    [[siteTariff], 'Site electricity tariff', headers.tariff],
    [configurableVectors, 'Energy vector prices', headers.vectors],
    [consumption, 'Consumption asset metrics', null],
    [renewables, 'Generation asset metrics', null],
    [assetTable, 'Electricity assets w generation', null],
    [totalRenewables, 'Elec consumption w generation', null],
    [hydrogen, 'Hydrogen overview', null],
    [hydrogenDiff, 'Hydrogen asset metrics', null],
    [fuelStorageLandRequirements, 'Fuel storage volume req', null],
    [fuelStorageLandRequirementsElectrolyser, 'Fuel storage w electrolyser', null],
    [genAssetLandRequirements, 'Gen asset land requirements', null]
  ].forEach(([state, sheetName, sheetHeader]) => {
    if (!state.length) return
    let sheet
    let headerRow
    if (state.every(e => Array.isArray(e))) {
      const transposedState = state[0].map((_, index) =>
        state.map(row => row[index])
      )

      transposedState.push([], ['Read only sheet. Any changes made here won\'t be read by EVIE app.'])

      sheet = XLSX.utils.aoa_to_sheet(transposedState)
      sheet['!protect'] = true
      headerRow = transposedState[0]
    } else {
      sheet = XLSX.utils.json_to_sheet(state)
      headerRow = Object.values(sheetHeader)

      XLSX.utils.sheet_add_aoa(sheet, [headerRow])
    }

    let longestCol = 12
    for (const cell in sheet) {
      if (cell[0] === 'A') {
        const value = sheet[cell].v
        longestCol = (typeof value === 'string' && value.length > longestCol)
          ? value.length
          : longestCol
      }
    }

    sheet['!cols'] = headerRow.map(({ length }) => (
      { wch: length ? Math.max(length, 12) : 12 })
    )
    sheet['!cols'][0].wch = longestCol

    XLSX.utils.book_append_sheet(workbook, sheet, sheetName)
  })

  XLSX.writeFile(workbook, `${getDateStr()} - ${siteName}.xlsx`, { compression: true })
}

/**
 * Parses a correctly formatted Microsoft Excel spreadsheet (.xlsx) to
 * JavaScript objects.
 *
 * @param data {Buffer} - File buffer, as returned by fs.readFile or the files
 * property of an input event
 * @param allowedVectors {Object[]} - The configured energy vectors
 * @returns {[
 *  Array<Object>,
 *  Array<Object>,
 *  Object,
 *  Object,
 *  Array<Object>
 * ]} A five item array comprising:
 *
 * - An array of consumption asset objects
 * - An array of renewable generation asset objects
 * - A hydrogen electrolyser object
 * - A site tariff object
 * - An array of energy vector objects
 * @throws {TypeError} - If the spreadsheet doesn't match the format of one
 * downloaded from EVIE or if any energy vectors in the spreadsheet don't match
 * the energy vectors in the second argument
 */
export const loadXlsx = (data, allowedVectors) => {
  const workbook = XLSX.read(data)

  const worksheets = [
    ['Consumption assets', headers.consumption],
    ['Generation assets', headers.generation],
    ['Hydrogen electrolyser', headers.electrolyser],
    ['Site electricity tariff', headers.tariff],
    ['Energy vector prices', headers.vectors]
  ].map(([sheetName, sheetHeader]) => {
    const sheet = workbook.Sheets[sheetName]
    XLSX.utils.sheet_add_aoa(sheet, [Object.keys(sheetHeader)])
    return sheet
  })

  const [
    newConsumption,
    newGeneration,
    newElectrolyser,
    newTariff,
    newVectorPrices
  ] = worksheets

  const consumptionAssets = XLSX.utils.sheet_to_json(newConsumption)
  const generationAssets = XLSX.utils.sheet_to_json(newGeneration)
  const electrolyser = XLSX.utils.sheet_to_json(newElectrolyser)[0]
  const siteTariff = XLSX.utils.sheet_to_json(newTariff)[0]
  const configurableVectors = XLSX.utils.sheet_to_json(newVectorPrices)

  const ajv = new Ajv({ multipleOfPrecision: 2 })
  const validate = ajv.compile(fileSchema)

  if (!validate(
    {
      consumptionAssets,
      generationAssets,
      electrolyser,
      siteTariff,
      energyVectors: configurableVectors
    }
  )
  ) {
    throw new TypeError(
      validate
        .errors
        .map(
          ({ instancePath, message }) => `${instancePath}: ${message}`
        )
        .join('\n')
    )
  }

  const allowedFuelNames = allowedVectors.map(({ fuelName }) => fuelName)

  configurableVectors.forEach(({ fuelName }) => {
    if (!allowedFuelNames.includes(fuelName)) {
      throw new TypeError(`
         - Incorrect energy vector name in 'Energy vector prices' sheet. Allowed
        vector names: ${allowedFuelNames}
      `)
    }
  })

  allowedFuelNames.push('Electricity')

  consumptionAssets.forEach(({ assetName, currentVector, newVector }) => {
    if (
      !allowedFuelNames.includes(currentVector) ||
      !allowedFuelNames.includes(newVector)
    ) {
      throw new TypeError(`
         - Incorrect energy vector name in 'Consumption assets': ${assetName}. 
        Allowed vector names: ${allowedFuelNames}
      `)
    }
  })

  return [
    consumptionAssets,
    generationAssets,
    electrolyser,
    siteTariff,
    configurableVectors
  ]
}
