/* Copyright 2023 Swanbarton Limited */

/**
 * @param {number} kg - Kg value to be converted into tonnes
 * @param {number} decimalPlaces - Number of decimal places required in the output
 * @returns {number} - Converted tonnes value
 */
export function convertKgToTonnes (kg, decimalPlaces) {
  const dPlaceFactor = Math.pow(10, decimalPlaces)
  return (
    Math.round((kg / 1000) * dPlaceFactor) / dPlaceFactor)
}
