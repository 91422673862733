/* Copyright 2023 Swanbarton Limited */
import Plot from 'react-plotly.js'
import { vectorColors, costColours, tailwindStyles, graphColours } from '../constants'
import { capitaliseFirstLetter } from '../utils/capitaliser'


const ConsumptionMetricsGraph = ({
  metricsTable,
  consumptionAssets,
  energyVectors
}) => {
  const fuelNames = energyVectors.map((v) => v.fuelName)
  fuelNames.push('Electricity')

  const emissionsLabelData = []

  const scopeOneEmissionsValueData = []
  const scopeTwoEmissionsValueData = []

  const currentCostColor = costColours[0]
  const newCostColor = costColours[1]

  const costLabelData = []
  const costValueData = []

  const vectorText = []

  const scopeOneEmissionsColors = []
  const scopeTwoEmissionsColors = []
  const costColors = []

  const allLabels = []

  metricsTable.forEach(([
    assetName,
    currentScopeOneEmissions,
    currentScopeTwoEmissions,
    currentEmissions,
    newScopeOneEmissions,
    newScopeTwoEmissions,
    newEmissions,
    emissionsDifference,
    currentCost,
    newCost,
    costDifference
  ], index) => {
    let graphDisplayName = assetName
    let nameIteration = 2
    while (allLabels.includes(graphDisplayName)) {
      graphDisplayName = assetName + ` (${nameIteration})`
      nameIteration += 1
    }
    allLabels.push(graphDisplayName)

    if (currentScopeOneEmissions || currentScopeTwoEmissions) {
      if (assetName !== 'Total') {
        vectorText.push(capitaliseFirstLetter(consumptionAssets[index].currentVector))
        const currentVectorIndex = fuelNames.indexOf(consumptionAssets[index].currentVector)

        emissionsLabelData.push(`${graphDisplayName} - Current Emissions`)

        scopeOneEmissionsValueData.push(currentScopeOneEmissions)
        scopeOneEmissionsColors.push(vectorColors[currentVectorIndex].scopeOne)

        scopeTwoEmissionsValueData.push(currentScopeTwoEmissions)
        scopeTwoEmissionsColors.push(vectorColors[currentVectorIndex].scopeTwo)

        if (consumptionAssets[index].toConvert === true) {
          vectorText.push(capitaliseFirstLetter(consumptionAssets[index].newVector))
          const newVectorIndex = fuelNames.indexOf(consumptionAssets[index].newVector)

          emissionsLabelData.push(`${graphDisplayName} - Projected Emissions`)

          scopeOneEmissionsValueData.push(newScopeOneEmissions)
          scopeOneEmissionsColors.push(vectorColors[newVectorIndex].scopeOne)

          scopeTwoEmissionsValueData.push(newScopeTwoEmissions)
          scopeTwoEmissionsColors.push(vectorColors[newVectorIndex].scopeTwo)
        }
      }
    }

    if (currentCost || newCost) {
      if (assetName !== 'Total') {
        costLabelData.push(`${graphDisplayName} - Current Cost`)
        costValueData.push(currentCost)
        costColors.push(currentCostColor)

        if (consumptionAssets[index].toConvert === true) {
          costLabelData.push(`${graphDisplayName} - Projected Cost`)
          costValueData.push(newCost)
          costColors.push(newCostColor)
        }
      }
    }
  })

  const scopeOneTrace = {
    x: emissionsLabelData,
    y: scopeOneEmissionsValueData,
    marker: {
      color: scopeOneEmissionsColors
    },
    type: 'bar',
    name: 'Scope One'
  }

  const scopeTwoTrace = {
    x: emissionsLabelData,
    y: scopeTwoEmissionsValueData,
    text: vectorText,
    marker: {
      color: scopeTwoEmissionsColors
    },
    type: 'bar',
    name: 'Scope Two'
  }

  if (emissionsLabelData.length > 0 || costLabelData.length > 0) {
    return (
      <>
        {(emissionsLabelData.length > 0) &&
          <Plot
            data-testid='consumption-graph-emissions'
            className='block'
            data={[
              scopeOneTrace,
              scopeTwoTrace
            ]}
            layout={{
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              showlegend: false,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              autosize: true,
              margin: {
                b: 150
              },
              height: 450,
              barmode: 'stack',
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Emissions (t CO₂e)'
                }
              }
            }}
            useResizeHandler
            style={
              { width: '100%', height: '100%' }
            }
          />}
        {(costLabelData.length > 0) &&
          <Plot
            data-testid='consumption-graph-costs'
            data={[
              {
                type: 'bar',
                marker: {
                  color: costColors
                },
                x: costLabelData,
                y: costValueData
              }
            ]}
            layout={{
              paper_bgcolor: graphColours.backgroundColour,
              plot_bgcolor: graphColours.backgroundColour,
              autosize: true,
              margin: {
                b: 150
              },
              height: 400,
              font: {
                color: graphColours.textColour,
                size: 10
              },
              spikedistance: 0,
              hovermode: 'x unified',
              yaxis: {
                linecolor: graphColours.textColour,
                zerolinecolor: graphColours.textColour,
                showgrid: false,
                title: {
                  text: 'Annual Cost (GBP)'
                }
              }
            }}
            useResizeHandler
            style={
              { width: '100%', height: '100%' }
            }
          />}
      </>
    )
  } else {
    return (
      <h4
        className={'ml-5 mt-5 ' + tailwindStyles.h4}
      >
        {'More data required to display consumption metrics graphs - ' +
          'consider adding assets with appropriate annual consumption'}
      </h4>
    )
  }
}

export default ConsumptionMetricsGraph
