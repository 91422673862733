/* Copyright 2023 Swanbarton Limited */
import { Children } from 'react'
import ConsumptionAsset from './ConsumptionAsset'
import { DefaultAsset, tailwindStyles } from '../constants'

const MIN_ASSETS = 0
export const MAX_ASSETS = 20

/**
 * @returns {React.ReactElement} - Component that renders HTML input elements and their associated labels
 * for entering the site's consumption asset vectors to be converted to new vectors
 */
const ConsumptionAssets = ({
  scenarioData,
  setScenarioData,
  scenarioIndex,
  vectors,
  configurableVectors
}) => {
  /**
   * A change handling function for modifying element of object arrays
   * (Specifically targets consumptionAssets array)
   *
   * @param {React.BaseSyntheticEvent} event - The event triggering the change
   * @param {string} property - The object property to be changed
   * @param {number} index - Index of the object in consumptionAssets to be changed
   */
  const handleChange = (event, property, index) => {
    const updateScenario = [...scenarioData]
    const { target } = event

    const numberFields = [
      'quantity',
      'currentEfficiency',
      'annualConsumption',
      'newEfficiency',
      'capex'
    ]
    if (
      numberFields.some(numberField => property === numberField) &&
      target.value !== ''
    ) {
      updateScenario[scenarioIndex].consumptionData[index][property] = Number(target.value)
    } else {
      updateScenario[scenarioIndex].consumptionData[index][property] = target.value
    }

    event.target.form.requestSubmit()
    setScenarioData(updateScenario)
  }

  const showMinusButton = (index) => {
    return (
      <button
        className={
          tailwindStyles.button +
          tailwindStyles.externalBorderStyle +
          tailwindStyles.buttonBackgroundStyle +
          ' ml-2 mr-2 mt-5 mb-2'
        }
        id={'remove-asset-' + index.toString() + '-' + scenarioIndex}
        data-testid={'remove-asset-' + index.toString() + '-' + scenarioIndex}
        onClick={(event) => {
          event.preventDefault()

          const updateScenario = [...scenarioData]
          updateScenario[scenarioIndex].consumptionData.splice(index, 1)
          setScenarioData(updateScenario)
        }}
      >
        Remove asset
      </button>
    )
  }

  const showPlusButton = (maxIn) => {
    if (scenarioData[scenarioIndex].consumptionData.length < maxIn) {
      return (
        <button
          className={
            tailwindStyles.button +
            tailwindStyles.externalBorderStyle +
            tailwindStyles.buttonBackgroundStyle +
            ' ml-[2px] w-[530px]'
          }
          id={'add-asset-' + scenarioIndex}
          data-testid={'add-asset-' + scenarioIndex}
          onClick={(event) => {
            event.preventDefault()
            const updateScenario = [...scenarioData]

            if (scenarioData[scenarioIndex].consumptionData.length < 1) {
              updateScenario[scenarioIndex].consumptionData.push(
                new DefaultAsset(vectors[0].fuelName, vectors[1].fuelName)
              )
            } else {
              const {
                assetName,
                quantity,
                currentVector,
                currentEfficiency,
                annualConsumption,
                newVector,
                newEfficiency,
                capex
              } = scenarioData[scenarioIndex].consumptionData[scenarioData[scenarioIndex].consumptionData.length - 1]

              updateScenario[scenarioIndex].consumptionData.push(
                new DefaultAsset(
                  currentVector,
                  newVector,
                  assetName,
                  quantity,
                  currentEfficiency,
                  annualConsumption,
                  newEfficiency,
                  capex
                )
              )
            }
            setScenarioData(updateScenario)
          }}
        >
          Add new asset
        </button>
      )
    }
  }

  return (
    <>
      <h2 className={tailwindStyles.h2 + ' mt-[15px] mb-2'}>
        Consumption Assets
      </h2>
      <section
        className='flex flex-wrap ml-5'
        data-testid={'asset-section-' + scenarioIndex}
      >
        {Children.toArray(scenarioData[scenarioIndex].consumptionData?.map((asset, index) => {
          return (
            <section className={
                tailwindStyles.conAssets.flexSection +
                tailwindStyles.externalBorderStyle +
                tailwindStyles.containerBackgroundStyle
              }
            >
              <ConsumptionAsset
                asset={asset}
                index={index}
                handleChange={handleChange}
                vectors={vectors}
                scenarioData={scenarioData}
                setScenarioData={setScenarioData}
                scenarioIndex={scenarioIndex}
                configurableVectors={configurableVectors}
              />
              {scenarioData[scenarioIndex].consumptionData.length > MIN_ASSETS && showMinusButton(index)}
            </section>
          )
        }
        ))}
      </section>
      <section
        className='grid ml-5 py-2'
      >{showPlusButton(MAX_ASSETS)}
      </section>
    </>
  )
}

export default ConsumptionAssets
