import { tailwindStyles } from '../constants'
import marRIUKLogo from '../MarRIUK-logo.svg'
import ricardoLogo from '../Ric.svg'
const { h4, footer } = tailwindStyles

const Footer = () =>
  <footer className={footer}>
    <img className='ml-5' src={ricardoLogo} alt='Ricardo' />
    <h4 className={h4 + ' text-center mt-3'}>
      Copyright 2023 Swanbarton Limited ©<br />
      App created in partnership with Ricardo PLC<br />
      Funding courtesy of MarRI-UK<br />
    </h4>
    <img className='mr-5 mb-5 mt-10' src={marRIUKLogo} alt='MarRI-UK' />
  </footer>

export default Footer
