import { signInWithPopup, OAuthProvider } from 'firebase/auth'
import { auth } from '../firebase'
import { Navigate, useNavigate } from 'react-router-dom'
import MsBtn from '../ms-symbollockup_signin_dark_short.svg'
import { tailwindStyles } from '../constants'
import { useAuthState } from 'react-firebase-hooks/auth'

const { REACT_APP_AZUREAD_TENANT } = process.env
const {
  containerBackgroundStyle,
  externalBorderStyle,
  conAssets
} = tailwindStyles

const Login = () => {
  const [user] = useAuthState(auth)
  const navigate = useNavigate()
  const provider = new OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    prompt: 'consent',
    tenant: REACT_APP_AZUREAD_TENANT
  })

  const onLogin = (e) => {
    e.preventDefault()
    signInWithPopup(auth, provider)
      .then(() => navigate('/'))
      .catch(({ code, message }) => console.error(code, message))
  }

  return (
    !user
      ? (
        <main
          data-testid='login' className={containerBackgroundStyle.concat(
            externalBorderStyle,
            ' mx-20 my-5 py-5 flex items-center justify-center'
          )}
        >
          <button>
            <img
              src={MsBtn} alt='Login button'
              onClick={onLogin}
            />
          </button>
          <p className={conAssets.label + ' inline'}>
            Sign in with work or school account
          </p>
        </main>
        )
      : <Navigate to='/' />
  )
}

export default Login
